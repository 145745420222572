window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.cohorts = window.locales.am.cohorts || {};window.locales.am.cohorts.user_id_verification_form = window.locales.am.cohorts.user_id_verification_form || {};window.locales.am.cohorts.user_id_verification_form = {...window.locales.am.cohorts.user_id_verification_form, ...{
    "verify_your_identity": "እባክዎ ማንነትዎን ያረጋግጡ",           
    "send_link": "ማስፈንጠሪያ ላክ",           
    "we_will_send_a_link": "ማረጋገጫውን ለማጠናቀቅ ማስፈንጠሪያ እንልክልዎታለን። የሚሰራ ካሜራ ባለው ስማርትፎን ላይ ይህን ማስፈንጠሪያ መከተል አለብዎት።",           
    "if_you_are_unable_to_complete": "የራስ-ሰር የማንነት ማረጋገጫ ሂደቱን ማጠናቀቅ ካልቻሉ፣ እባክዎ በ <a href=\"mailto:{{brandEmail}}\" target=\"_blank\" >{{brandEmail}}</a> ላይ ያነጋግሩን",           
    "why_we_do_this": "ለምን ይህን እናደርግ",           
    "send_an_email": "በምትኩ ኢሜይል ላክ",           
    "send_a_text": "በምትኩ ፅሁፍ ላክ",           
    "mobile_phone": "የሞባይል ስልክ ቁጥር",           
    "email": "የኢሜይል አድራሻ",           
    "link_has_been_sent": "ጊዜን የሚነካ ማስፈንጠሪያ ወደ {{formattedEmailOrPhone}} ተልኳል። የማረጋገጫ ሂደቱን ለማጠናቀቅ በስማርትፎንዎ ላይ ያለውን ማስፈንጠሪያ ይክፈቱ። <a ng-click = \"userIdVerificationViewModel.goBackToForm()\">ሌላ ማስፈንጠሪያ ላኩልኝ</a>።",           
    "success": "ስኬት!",           
    "verification_failed": "የማንነት ማረጋገጫ አልተሳካም።",           
    "verification_complete": "ስኬት! የማንነት ማረጋገጫዎ ተጠናቅቋል።",           
    "something_went_wrong": "የእርስዎን መታወቂያና ፎቶ ማረጋገጥ አልቻልንም። እባክዎ <a ng-click = \"userIdVerificationViewModel.goBackToForm()\">እንደገናከቆመበት ቀጥል ይሞክሩ</a>።",           
    "important_tips": "ጠቃሚ ምክሮች",           
    "place_document": "ሰነዱን በጨለማ፣ ጠፍጣፋ ወለል ላይ ያኑሩት",           
    "ensure_corners": "ሁሉም 4 ማዕዘኖች በፍሬሙ ውስጥ መታየታቸውን ያረጋግጡ",           
    "avoid_glare": "ነፀብራቅ፣ ብዥታና ጥላዎችን ያስወግዱ",           
    "take_clear_photo": "ግልፅ፣ በደንብ የሚታይ ፎቶ ያንሱ",           
    "ensure_head_visible": "መላው ጭንቅላት የሚታይና መሃል ላይ መሆኑን ያረጋግጡ",           
    "avoid_blur": "ብዥታና ጥላዎችን ያስወግዱ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.cohorts = window.locales.ar.cohorts || {};window.locales.ar.cohorts.user_id_verification_form = window.locales.ar.cohorts.user_id_verification_form || {};window.locales.ar.cohorts.user_id_verification_form = {...window.locales.ar.cohorts.user_id_verification_form, ...{
    "verify_your_identity": "يرجى التحقق من هويتك",
    "send_link": "أرسل الرابط",
    "why_we_do_this": "لماذا نفعل هذا",
    "send_an_email": "أرسل بريدًا إلكترونيًا بدلاً من ذلك",
    "send_a_text": "أرسل رسالة نصية بدلاً من ذلك",
    "mobile_phone": "رقم الهاتف الجوال",
    "email": "عنوان بريد الكتروني",
    "link_has_been_sent": "تم إرسال رابط حساس الوقت إلى {{formattedEmailOrPhone}} . افتح الرابط على هاتفك الذكي لإكمال عملية التحقق. <a ng-click = \"userIdVerificationViewModel.goBackToForm()\">أرسل لي رابطًا آخر</a> .",
    "success": "نجاح!",
    "verification_failed": "التحقق من الهوية فشل",
    "verification_complete": "نجاح! اكتمل التحقق من هويتك.",
    "something_went_wrong": "لم نتمكن من التحقق من هويتك وصورتك. يرجى <a ng-click = \"userIdVerificationViewModel.goBackToForm()\">المحاولة مرة أخرى</a> .",
    "we_will_send_a_link": "سنرسل لك رابطًا لإكمال عملية التحقق. يجب عليك اتباع هذا الرابط على هاتف ذكي بكاميرا تعمل.",
    "important_tips": "نصائح مهمة",
    "place_document": "ضع المستند على سطح مستوي داكن",
    "ensure_corners": "تأكد من رؤية جميع الزوايا الأربع في الإطار",
    "avoid_glare": "تجنب الوهج والظلال والظلال",
    "take_clear_photo": "التقط صورة واضحة ومضاءة جيدًا",
    "ensure_head_visible": "تأكد من رؤية الرأس بالكامل وتركيزه",
    "avoid_blur": "تجنب طمس والظلال",
    "if_you_are_unable_to_complete": "إذا لم تتمكن من إكمال عملية التحقق من الهوية الآلية ، فالرجاء الاتصال بنا على <a href=\"mailto:{{brandEmail}}\" target=\"_blank\" >{{brandEmail}}</a>"
}
};window.locales.en = window.locales.en || {};window.locales.en.cohorts = window.locales.en.cohorts || {};window.locales.en.cohorts.user_id_verification_form = window.locales.en.cohorts.user_id_verification_form || {};window.locales.en.cohorts.user_id_verification_form = {...window.locales.en.cohorts.user_id_verification_form, ...{
    "verify_your_identity": "Please Verify Your Identity",
    "send_link": "Send Link",
    "we_will_send_a_link": "We'll send you a link to complete verification. You must follow this link on a smartphone with a working camera.",
    "if_you_are_unable_to_complete": "If you are unable to complete the automated identity verification process, please contact us at <a href=\"mailto:{{brandEmail}}\" target=\"_blank\" >{{brandEmail}}</a>",
    "why_we_do_this": "Why we do this",
    "send_an_email": "Send an email instead",
    "send_a_text": "Send a text instead",
    "mobile_phone": "Mobile Phone Number",
    "email": "Email Address",
    "link_has_been_sent": "Your time-sensitive link has been sent to {{formattedEmailOrPhone}}. Open the link on your smartphone to complete the verification process. <a ng-click = \"userIdVerificationViewModel.goBackToForm()\">Send me another link</a>.",
    "success": "Success!",
    "verification_failed": "Identity Verification Failed",
    "verification_complete": "Success! Your identity verification is complete.",
    "something_went_wrong": "We were unable to verify your ID and photo. Please <a ng-click = \"userIdVerificationViewModel.goBackToForm()\">try again</a>.",
    "important_tips": "Important Tips",
    "place_document": "Place document on dark, flat surface",
    "ensure_corners": "Ensure all 4 corners are visible in frame",
    "avoid_glare": "Avoid glare, blur, and shadows",
    "take_clear_photo": "Take a clear, well-lit photo",
    "ensure_head_visible": "Ensure entire head is visible and centered",
    "avoid_blur": "Avoid blur and shadows"
}
};window.locales.es = window.locales.es || {};window.locales.es.cohorts = window.locales.es.cohorts || {};window.locales.es.cohorts.user_id_verification_form = window.locales.es.cohorts.user_id_verification_form || {};window.locales.es.cohorts.user_id_verification_form = {...window.locales.es.cohorts.user_id_verification_form, ...{
    "verify_your_identity": "Verifique por favor su Identidad",
    "send_link": "Enviar un enlace",
    "why_we_do_this": "Porque hacemos esto",
    "send_an_email": "Enviar un correo electrónico en su lugar",
    "send_a_text": "Enviar un texto en su lugar",
    "mobile_phone": "Número de teléfono móvil",
    "email": "Dirección de correo electrónico",
    "link_has_been_sent": "Su enlace sensible al tiempo se ha enviado a {{formattedEmailOrPhone}} . Abra el enlace en su teléfono inteligente para completar el proceso de verificación. <a ng-click = \"userIdVerificationViewModel.goBackToForm()\">Envíame otro enlace</a> .",
    "success": "¡Éxito!",
    "verification_failed": "Fallo en la verificación de identidad",
    "verification_complete": "¡Éxito! Su verificación de identidad está completa.",
    "something_went_wrong": "No hemos podido verificar su identificación y foto. Por favor <a ng-click = \"userIdVerificationViewModel.goBackToForm()\">intente de nuevo</a> .",
    "we_will_send_a_link": "Te enviaremos un enlace para completar la verificación. Debe seguir este enlace en un teléfono inteligente con una cámara que funcione.",
    "important_tips": "Consejos importantes",
    "place_document": "Coloque el documento en una superficie plana y oscura.",
    "ensure_corners": "Asegúrate de que las 4 esquinas sean visibles en el marco",
    "avoid_glare": "Evita el deslumbramiento, el desenfoque y las sombras.",
    "take_clear_photo": "Toma una foto clara y bien iluminada.",
    "ensure_head_visible": "Asegúrese de que toda la cabeza esté visible y centrada",
    "avoid_blur": "Evita el desenfoque y las sombras.",
    "if_you_are_unable_to_complete": "Si no puede completar el proceso automatizado de verificación de identidad, contáctenos en <a href=\"mailto:{{brandEmail}}\" target=\"_blank\" >{{brandEmail}}</a>"
}
};window.locales.it = window.locales.it || {};window.locales.it.cohorts = window.locales.it.cohorts || {};window.locales.it.cohorts.user_id_verification_form = window.locales.it.cohorts.user_id_verification_form || {};window.locales.it.cohorts.user_id_verification_form = {...window.locales.it.cohorts.user_id_verification_form, ...{
    "verify_your_identity": "Per Favore Verifica La Tua Identità",
    "send_link": "Invia il link",
    "why_we_do_this": "Perché lo facciamo",
    "send_an_email": "Manda una mail",
    "send_a_text": "Invia invece un testo",
    "mobile_phone": "Numero di cellulare",
    "email": "Indirizzo email",
    "link_has_been_sent": "Il tuo link time-sensitive è stato inviato a {{formattedEmailOrPhone}} . Apri il link sul tuo smartphone per completare la procedura di verifica. <a ng-click = \"userIdVerificationViewModel.goBackToForm()\">Inviami un altro link</a>",
    "success": "Successo!",
    "verification_failed": "Verifica dell'identità non riuscita",
    "verification_complete": "Successo! La tua verifica dell'identità è completa.",
    "something_went_wrong": "Non siamo riusciti a verificare il tuo ID e la tua foto. Per favore <a ng-click = \"userIdVerificationViewModel.goBackToForm()\">riprova</a> .",
    "we_will_send_a_link": "Ti invieremo un link per completare la verifica. È necessario seguire questo collegamento su uno smartphone con una fotocamera funzionante.",
    "important_tips": "Suggerimenti importanti",
    "place_document": "Posiziona il documento su una superficie scura e piatta",
    "ensure_corners": "Assicurarsi che tutti e 4 gli angoli siano visibili nel frame",
    "avoid_glare": "Evita l'abbagliamento, la sfocatura e le ombre",
    "take_clear_photo": "Scatta una foto chiara e ben illuminata",
    "ensure_head_visible": "Assicurarsi che l'intera testa sia visibile e centrata",
    "avoid_blur": "Evita le sfocature e le ombre",
    "if_you_are_unable_to_complete": "Se non riesci a completare il processo di verifica automatica dell'identità, ti preghiamo di contattarci a <a href=\"mailto:{{brandEmail}}\" target=\"_blank\" >{{brandEmail}}</a>"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.cohorts = window.locales.zh.cohorts || {};window.locales.zh.cohorts.user_id_verification_form = window.locales.zh.cohorts.user_id_verification_form || {};window.locales.zh.cohorts.user_id_verification_form = {...window.locales.zh.cohorts.user_id_verification_form, ...{
    "verify_your_identity": "请验证你的身份",           
    "send_link": "发送链接",           
    "why_we_do_this": "我们这样做的原因",           
    "send_an_email": "发送电子邮件",           
    "send_a_text": "发送信息",           
    "mobile_phone": "手机号码",           
    "email": "电子邮箱",           
    "link_has_been_sent": "具有时效性的链接已发送至{{formattedEmailOrPhone}}。打开智能手机上的链接，完成验证过程。<a ng-click = \"userIdVerificationViewModel.goBackToForm()\">向我发送另一个链接</a>。",           
    "success": "成功！",           
    "verification_failed": "身份验证失败",           
    "verification_complete": "成功！你的身份验证已完成。",           
    "something_went_wrong": "我们无法确认你的身份和照片。请<a ng-click = \"userIdVerificationViewModel.goBackToForm()\">再试一次</a>。",           
    "we_will_send_a_link": "我们会向你发送一个链接来完成验证。你必须在带摄像头的智能手机上点击此链接。",           
    "important_tips": "重要提示",           
    "place_document": "将文件放在深色的平面上",           
    "ensure_corners": "确保4个角皆位于方框内",           
    "avoid_glare": "避免眩光、模糊和阴影",           
    "take_clear_photo": "拍摄清晰明亮的照片",           
    "ensure_head_visible": "确保整个头部可见并居中",           
    "avoid_blur": "避免模糊和阴影",           
    "if_you_are_unable_to_complete": "如果你无法完成自动身份验证流程，请通过<a href=\"mailto:{{brandEmail}}\" target=\"_blank\" >{{brandEmail}}</a>与我们联系。"           
}
};