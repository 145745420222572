import moment from 'moment';
import { type Config as FrontRoyalConfigType } from 'FrontRoyalConfig';
import moize from 'moize';
import { addInDefaultTimeZone } from 'DateHelpers';
import { getProgramConfigValue } from 'Program';
import { type IdVerificationPeriod, type Cohort } from './Cohort.types';
import { verificationPeriodStartDate, verificationPeriodPastDue } from './IdVerificationPeriod';

function _formatNameAndStartDate({ name, startDate: startDateTimestamp }: { name: string; startDate: number }) {
    const startDate = new Date(1000 * startDateTimestamp);
    const starts = startDate! < new Date() ? 'Started' : 'Starts';
    return `${name} (${starts}: ${moment(startDate).format('MMM Do, YYYY')})`;
}

export const formatNameAndStartDate = moize(_formatNameAndStartDate);

export function getActiveIdVerificationPeriod(cohort: Cohort): IdVerificationPeriod | null {
    return (
        cohort.idVerificationPeriods
            ?.slice()
            .reverse()
            .find(period => verificationPeriodStartDate(cohort, period) < new Date()) || null
    );
}

export function getActiveIdVerificationPeriodIndex(cohort: Cohort): number | null {
    const index = cohort.idVerificationPeriods
        ?.slice()
        .reverse()
        .findIndex(period => verificationPeriodStartDate(cohort, period) < new Date());
    return index == null || index < 0 ? null : index;
}

export function activeIdVerificationPeriodIsPastDue(cohort: Cohort): boolean {
    const activeIdVerificationPeriod = getActiveIdVerificationPeriod(cohort);
    if (!activeIdVerificationPeriod) return false;
    return verificationPeriodPastDue(cohort, activeIdVerificationPeriod);
}

// Eventually we may need to support other offsetFroms here. For example, graduation_date is offset from the end
type OffsetFrom = 'startDate';

export function _getRelativeDate(cohort: Cohort, daysOffset: number, offsetFrom?: OffsetFrom): Date;
export function _getRelativeDate(cohort: Cohort, daysOffset: null | undefined, offsetFrom?: OffsetFrom): null;
export function _getRelativeDate(
    cohort: Cohort,
    daysOffset: null | undefined | number,
    offsetFrom: OffsetFrom = 'startDate',
) {
    if (daysOffset == null) return null;

    const baseDate = new Date(1000 * cohort[offsetFrom]);
    return addInDefaultTimeZone(baseDate, daysOffset, 'days');
}

export const getRelativeDate = moize(_getRelativeDate);

export function getStreamIsInCurriculum(cohort: Cohort, streamLocalePackId: string): boolean {
    if (!cohort) return false;
    return !!cohort.streamLocalePacksInfo[streamLocalePackId];
}

export function getConcentrationPlaylistPackIds(cohort: Cohort) {
    return cohort.playlistCollections.reduce(
        (memo: string[], playlistCollection) => memo.concat(playlistCollection.requiredPlaylistPackIds),
        [],
    );
}

export function getSpecializationPlaylistPackIds(cohort: Cohort) {
    return cohort.specializationPlaylistPackIds;
}

export function getFoundationsPlaylistPackId(cohort: Cohort) {
    if (!cohort.supportsFoundationsPlaylist) return null;

    return cohort.foundationsPlaylistLocalePack?.id || null;
}

export function getPlaylistPackIds(cohort: Cohort) {
    return getConcentrationPlaylistPackIds(cohort).concat(cohort.specializationPlaylistPackIds);
}

export function pastEnrollmentDeadline(cohort: Cohort) {
    const deadline = getRelativeDate(cohort, cohort.enrollmentDeadlineDaysOffset);
    if (!deadline) return false;
    return new Date() > deadline;
}

export function pastEndDate(cohort: Cohort) {
    const endDate = new Date(1000 * cohort.endDate);
    return endDate < new Date();
}

export function getRequiredStreamPackIdsFromPeriods(cohort: Cohort, maxPeriod?: number) {
    if (maxPeriod == null) {
        maxPeriod = cohort.periods.length - 1;
    }

    if (maxPeriod < 0) return [];

    const localePackIds = cohort.periods
        .slice(0, maxPeriod + 1)
        .flatMap(period => period.streamEntries)
        .reduce((acc: string[], streamEntry) => {
            if (streamEntry.required) return acc.concat([streamEntry.localePackId]);
            return acc;
        }, []);

    return [...new Set(localePackIds)];
}

export function getPeriodIndexForRequiredLocalePackId(cohort: Cohort, localePackId: string) {
    return cohort.periods.findIndex(period =>
        period.streamEntries.some(streamEntry => streamEntry.localePackId === localePackId && streamEntry.required),
    );
}

export function requiresTILADisclosure(cohort: Cohort) {
    // We started requiring TILA disclosures in cycle 52; however, the process
    // wasn't automated until cycle 54 (see supports_automated_tila_disclosure?).
    return (
        getProgramConfigValue(cohort.programType, 'requiresTILADisclosure') &&
        new Date(1000 * cohort.startDate) >= new Date('2023/04/24')
    );
}

// FIXME: this is duplicated in schedulable, but we need to make a base cohort type to fix it
export function requiresProctoring(cohort: Cohort, frontRoyalConfig: FrontRoyalConfigType) {
    return (
        frontRoyalConfig.biosigEnabled() &&
        getProgramConfigValue(cohort.programType, 'requiresProctoring') &&
        // We started requiring proctoring in cycle 54.
        // Compare against UTC time because the start date of
        // Mon, 14 Aug 2023 08:00:00 UTC
        // is Sun Aug 13 2023 22:00:00 GMT-1000 in Hawaii-Aleutian Standard Time
        new Date(1000 * cohort.startDate) >= new Date('2023-08-14T08:00:00Z')
    );
}

export function getIsStreamInFoundationsPlaylist(cohort: Cohort, streamLocalePackId: string) {
    return cohort.foundationsLessonStreamLocalePackIds.includes(streamLocalePackId);
}

export function getStartDate(cohort: Cohort) {
    return new Date(1000 * cohort.startDate);
}

export function getRegistrationDeadline(cohort: Cohort) {
    return getRelativeDate(cohort, cohort.registrationDeadlineDaysOffset);
}

export function registrationOpenDate(cohort: Cohort) {
    return new Date(moment(getRegistrationDeadline(cohort)).subtract(180, 'days').valueOf());
}

export function afterRegistrationOpenDate(cohort: Cohort) {
    if (getProgramConfigValue(cohort.programType, 'isExecEd')) return true; // HACK: https://trello.com/c/gbvEER9G
    return moment().valueOf() >= moment(registrationOpenDate(cohort)).startOf('day').valueOf();
}

export function registrationDeadlineHasPassed(cohort: Cohort) {
    return moment().valueOf() >= moment(getRegistrationDeadline(cohort)).valueOf();
}
