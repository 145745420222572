/* eslint-disable func-names */
import { Brand, formattedBrandName } from 'AppBranding';
import { QUANTIC_DOMAIN, QUANTIC_STUDENT_EMAIL_DOMAIN, SMARTLY_DOMAIN, VALAR_DOMAIN } from 'PedagoDomainConstants';
import {
    HELPSCOUT_BEACON_EMBA_ID,
    HELPSCOUT_BEACON_EMBASL_ID,
    HELPSCOUT_BEACON_HYBRID_ID,
    HELPSCOUT_BEACON_MBALM_ID,
} from 'HelpScoutBeacon';
import { InstitutionId } from 'Institutions';
import moment from 'moment-timezone';
import { keyBy } from 'lodash/fp';
import { StudentNetworkQuickFilterKey } from 'StudentNetwork/StudentNetwork.types';
import { ArticleSiteId } from 'Resources/Resources.types';
import { NominationsState } from 'Nominations';
import {
    ProgramTypeConfigs,
    ProgramType,
    PHYSICAL_DIPLOMA,
    DELIVERED_DIGITAL_CERTIFICATE,
    DYNAMIC_DOWNLOADABLE_CERTIFICATE,
} from 'Program';

import angularModule from './cohorts_module';

angularModule.factory('Schedulable', [
    '$injector',

    $injector => {
        const AModuleAbove = $injector.get('AModuleAbove');
        const AdmissionRound = $injector.get('AdmissionRound');
        const IdVerificationPeriod = $injector.get('IdVerificationPeriod');
        const dateHelper = $injector.get('dateHelper');
        const ngToast = $injector.get('ngToast');
        const Period = $injector.get('Period');
        const SupportsRelativeDates = $injector.get('SupportsRelativeDates');
        const ConfigFactory = $injector.get('ConfigFactory');
        const CERTIFICATE_PROGRAM_TYPES = [];

        const baseDegreeProgram = {
            key: null,
            label: null, // internal
            inDevelopment: () => false,
            supportsAdmissionRounds: true,
            supportsGradebook: true,
            supportsEnrollmentDeadline: true,
            supportsDeferralLink: true,
            supportsDocumentUpload: true,
            supportedDeprecatedIdUpload: false,
            supportsAcceptanceMessage: true,
            supportsSpecializations: true,
            canPreviewCareerProfileAfterApplying: false,
            excludesFoundationsPlaylistOnAcceptance: true,
            supportsExercises: true,
            supportsDelayedCareerNetworkAccessOnAcceptance: true,
            shortProgramTitle: null,
            fullTitle: null,
            programAchievementGraphicProgramTitleLines: [],
            modifyPaymentDetailsModalProgramTitle: null,
            acceptedScreenTitleHtml: null,
            studentDashboardProgramBoxTitleHtml: null,
            studentDashboardWelcomeBoxKey: 'degree_program',
            studentDashboardProgramBoxSubtitleKey: 'degree_program',
            studentDashboardProgramBoxShowProgressBar: true,
            learningBoxDescriptionKey: 'description_degree_program',
            learningBoxTitleKey: 'degree_program_curriculum',
            showButtonKey: 'show_curriculum',
            applicationStatusPendingSecondaryMessageKey: 'pending_secondary_message_degree_program',
            applicationStatusRejectedPrimaryMessageKey: 'rejected_primary_message_reapply_to_next',
            applicationStatusRejectedAfterPreAcceptedPrimaryMessageKey:
                'rejected_primary_message_reapply_to_next_rejected_after_pre_accepted',
            applicationStatusRejectedPrimaryMessageKeyCantReapply:
                'rejected_primary_message_reapply_to_next_cant_reapply',
            applicationStatusRejectedAfterPreAcceptedPrimaryMessageKeyCantReapply:
                'rejected_primary_message_reapply_to_next_rejected_after_pre_accepted_cant_reapply',
            applicationStatusAcceptedPrimaryMessageKey: 'accepted_primary_message_default',
            applicationStatusAcceptedPrimaryMessageDefaultBrandName: null,
            canFilterNetworkForMyClass: true,
            requiresEnglishLanguageProficiency: true,
            requiresMailingAddress: true,
            programTitle: null,
            programSwitcherMenuTitle: null,
            supportsEnrollmentSidebar: true,
            supportsEnrollmentAgreement: true,
            supportsPresentationProjects: true,
            supportsAvatarUploadInAccountPage: true,
            supportsEditingCareerProfile: true,
            supportsIsolatedNetwork: false,
            enrollmentFaqLink: `/help/enrollment-verification-and-documentation`,
            studentEmailDomain: null,
            supportEmailDomain: null,
            defaultSupportEmailUsername: null,
            supportsEnrollmentDocumentsDeadline: true,
            supportsOfficialTranscriptsDeadline: true,
            helpScoutBeaconID: null,
            institutionID: null,
            numPrecedingMbaCohorts: 0,
            adminCohortCalendarColorDesaturation: 100,
            supportsValarOptIn: true,
            confirmBrandRedirect: true,
            supportsResourcesTab: true,
            topMessageLocaleKeyPrefix: null,
            studentNetworkQuickFilterConfigKeys: [],
            helpScoutArticleSiteId: null,
            supportsCohortPreApproval: false,
            supportsWelcomeBox: false,
            hasOrientation: true,
            supportsProgressReport: true,
        };

        const baseExecEdCertificate = {
            institutionID: InstitutionId.quantic,
            supportEmailDomain: QUANTIC_DOMAIN,
            supportsDocumentUpload: false,
            supportsResourcesTab: true,
            supportsGradebook: true,
            supportsEditingCareerProfile: true,
            supportsSpecializations: true,
            enrollmentFaqLink: `/help/enrollment-verification-and-documentation`,
            studentNetworkQuickFilterConfigKeys: () => [StudentNetworkQuickFilterKey.allExecutiveEducation],
            canFilterNetworkForMyClass: false,
            inDevelopment: () => false,
            helpScoutArticleSiteId: ArticleSiteId.execEd,
            supportsAdmissionRounds: true,
            supportsEnrollmentAgreement: true,
            supportsEnrollmentDocumentsDeadline: true,
            supportsEnrollmentDeadline: true,
            supportsEnrollmentSidebar: true,
            requiresMailingAddress: false,
            supportsCohortPreApproval: true,
            supportsWelcomeBox: false,
            hasOrientation: false,
            supportsProgressReport: false,
        };

        const baseVLACertificate = {
            // Note: this was originally created based on the `baseDegreeProgram` defined above and then modified,
            // but we don't want to spread `...baseDegreeProgram` here since it is not a degree program and so would cause confusion.
            inDevelopment: () => false,
            // `supportsAdmissionRounds` is used to determine whether cohorts appear in the admin calendar, so if VLA cohorts are desired
            // for the calendar, we'll have to switch this to true or create a new calendar-specific config property.
            supportsAdmissionRounds: false,
            supportsGradebook: false,
            supportsEnrollmentDeadline: false,
            supportsDeferralLink: true,
            supportsDocumentUpload: false,
            supportsAcceptanceMessage: false,
            supportsSpecializations: true,
            canPreviewCareerProfileAfterApplying: false,
            excludesFoundationsPlaylistOnAcceptance: true,
            supportsExercises: true,
            supportsDelayedCareerNetworkAccessOnAcceptance: true,
            programAchievementGraphicProgramTitleLines: [],
            modifyPaymentDetailsModalProgramTitle: null,
            acceptedScreenTitleHtml: null,
            studentDashboardWelcomeBoxKey: 'welcome_generic',
            studentDashboardProgramBoxSubtitleKey: 'generic',
            studentDashboardProgramBoxShowProgressBar: true,
            learningBoxDescriptionKey: 'description_playlists',
            learningBoxTitleKey: 'playlists',
            showButtonKey: 'show_curriculum',
            applicationStatusPendingSecondaryMessageKey: 'pending_secondary_message_other',
            applicationStatusRejectedPrimaryMessageKey: 'rejected_primary_message_reapply_to_next',
            applicationStatusRejectedPrimaryMessageKeyCantReapply:
                'rejected_primary_message_reapply_to_next_cant_reapply',
            applicationStatusRejectedAfterPreAcceptedPrimaryMessageKeyCantReapply:
                'rejected_primary_message_reapply_to_next_rejected_after_pre_accepted_cant_reapply',
            applicationStatusAcceptedPrimaryMessageKey: 'accepted_primary_message_default',
            applicationStatusAcceptedPrimaryMessageDefaultBrandName: formattedBrandName('short', Brand.valar),
            canFilterNetworkForMyClass: false,
            requiresEnglishLanguageProficiency: true,
            requiresMailingAddress: true,
            supportsEnrollmentSidebar: false,
            supportsEnrollmentAgreement: false,
            supportsPresentationProjects: true,
            supportsAvatarUploadInAccountPage: true,
            supportsEditingCareerProfile: false,
            supportsIsolatedNetwork: false,
            // Although VLA users will have a graduation date, they have a separate ceremony where they receive their certificate/diploma
            enrollmentFaqLink: '/help/enrollment-verification-and-documentation',
            studentEmailDomain: null,
            supportEmailDomain: VALAR_DOMAIN,
            defaultSupportEmailUsername: 'support',
            supportsEnrollmentDocumentsDeadline: false,
            supportsOfficialTranscriptsDeadline: false,
            helpScoutBeaconID: null,
            numPrecedingMbaCohorts: 0,
            adminCohortCalendarColorDesaturation: 25,
            supportsValarOptIn: false,
            confirmBrandRedirect: true,
            supportsResourcesTab: false,
            topMessageLocaleKeyPrefix: null,
            studentNetworkQuickFilterConfigKeys: [],
            helpScoutArticleSiteId: ArticleSiteId.valar,
            supportsCohortPreApproval: false,
            supportsWelcomeBox: false,
            hasOrientation: false,
            supportsProgressReport: false,
        };

        const programTypes = [
            {
                ...baseDegreeProgram,
                ...ProgramTypeConfigs[ProgramType.mba],
                key: ProgramType.mba,
                programAchievementGraphicProgramTitleLines: ['MBA'],
                modifyPaymentDetailsModalProgramTitle: 'MBA',
                acceptedScreenTitleHtml: 'MBA',
                studentDashboardProgramBoxTitleHtml: `The ${formattedBrandName(
                    'short',
                    Brand.quantic,
                )} <br class="hidden-xs hidden-sm"> MBA`,
                applicationStatusAcceptedPrimaryMessageDefaultBrandName: formattedBrandName('short', Brand.quantic),
                programTitle: `${formattedBrandName('short', Brand.quantic)} MBA`,
                programSwitcherMenuTitle: 'Master of Business Administration',
                transcriptSectionTitleDegree: 'MBA',
                institutionID: InstitutionId.quantic,
                studentEmailDomain: QUANTIC_STUDENT_EMAIL_DOMAIN,
                supportEmailDomain: QUANTIC_DOMAIN,
                defaultSupportEmailUsername: 'mba',
                helpScoutBeaconID: HELPSCOUT_BEACON_HYBRID_ID,
                topMessageLocaleKeyPrefix: 'top_message_business_admin',
                studentNetworkQuickFilterConfigKeys: [
                    StudentNetworkQuickFilterKey.allMba,
                    StudentNetworkQuickFilterKey.allEmba,
                ],
                helpScoutArticleSiteId: ArticleSiteId.quantic,
                supportedDeprecatedIdUpload: true,
                gradingPolicyFaqUrl() {
                    // cycle 39-
                    if (this.startDate < new Date(1634544000 * 1000)) {
                        // Older cohorts don't have an individual grading policy like the cohorts in more recent cycles,
                        // so we send them to the FAQ page covering the general overview of the grading policy as catchall.
                        return 'https://support.quantic.edu/article/1354-grading-policy';
                    }

                    // cycle 40 - 50
                    if (this.startDate <= new Date(1673254800 * 1000)) {
                        return 'https://support.quantic.edu/article/534-how-are-mba-grades-calculated-class-of-december-2022-and-beyond';
                    }

                    // cycle 51 - 55
                    if (this.startDate <= new Date(1694419200 * 1000)) {
                        return 'https://support.quantic.edu/article/967-how-are-mba-grades-calculated-class-of-june-2024-and-beyond';
                    }

                    // cycle 56+
                    return 'https://support.quantic.edu/article/1091-how-are-mba-grades-calculated-class-of-january-2025-and-beyond';
                },
            },
            {
                ...baseDegreeProgram,
                ...ProgramTypeConfigs[ProgramType.emba],
                key: ProgramType.emba,
                programAchievementGraphicProgramTitleLines: ['Executive MBA'],
                modifyPaymentDetailsModalProgramTitle: 'Executive MBA',
                acceptedScreenTitleHtml: 'Executive MBA',
                studentDashboardProgramBoxTitleHtml: `The ${formattedBrandName(
                    'short',
                    Brand.quantic,
                )} <br class="hidden-xs hidden-sm"> EMBA`,
                applicationStatusAcceptedPrimaryMessageDefaultBrandName: formattedBrandName('short', Brand.quantic),
                programTitle: `${formattedBrandName('short', Brand.quantic)} EMBA`,
                programSwitcherMenuTitle: 'Executive Master of Business Administration',
                transcriptSectionTitleDegree: 'EMBA',
                supportsIsolatedNetwork: true,
                enrollmentFaqLink: `/help/enrollment-verification-and-documentation`,
                studentEmailDomain: QUANTIC_STUDENT_EMAIL_DOMAIN,
                supportEmailDomain: QUANTIC_DOMAIN,
                defaultSupportEmailUsername: 'emba',
                helpScoutBeaconID: HELPSCOUT_BEACON_EMBA_ID,
                institutionID: InstitutionId.quantic,
                numPrecedingMbaCohorts: 4, // 4 MBA cohorts prior to the first EMBA cohort
                adminCohortCalendarColorDesaturation: 50,
                topMessageLocaleKeyPrefix: 'top_message_business_admin',
                studentNetworkQuickFilterConfigKeys: [
                    StudentNetworkQuickFilterKey.allMba,
                    StudentNetworkQuickFilterKey.allEmba,
                ],
                helpScoutArticleSiteId: ArticleSiteId.quantic,
                supportedDeprecatedIdUpload: true,
                gradingPolicyFaqUrl() {
                    // cycle 50-
                    if (this.startDate <= new Date(1673254800 * 1000)) {
                        return 'https://support.quantic.edu/article/494-how-are-emba-grades-calculated';
                    }

                    // cycle 51 - 55
                    if (this.startDate <= new Date(1694419200 * 1000)) {
                        return 'https://support.quantic.edu/article/964-how-are-emba-grades-calculated-class-of-june-2024-and-beyond';
                    }

                    // cycle 56+
                    return 'https://support.quantic.edu/article/1088-how-are-emba-grades-calculated-class-of-january-2025-and-beyond';
                },
            },
            {
                ...baseDegreeProgram,
                ...ProgramTypeConfigs[ProgramType.mba_leadership],
                key: ProgramType.mba_leadership,
                programAchievementGraphicProgramTitleLines: ['MBA'],
                modifyPaymentDetailsModalProgramTitle: 'MBA',
                acceptedScreenTitleHtml: 'MBA',
                studentDashboardProgramBoxTitleHtml: `The ${formattedBrandName(
                    'short',
                    Brand.valar,
                )} <br class="hidden-xs hidden-sm"> MBA`,
                applicationStatusAcceptedPrimaryMessageDefaultBrandName: formattedBrandName('long', Brand.valar),
                programTitle: `${formattedBrandName('short', Brand.valar)} MBA`,
                programSwitcherMenuTitle: 'Master of Business Administration in Leadership and Management',
                transcriptSectionTitleDegree: 'MBA',
                enrollmentFaqLink: `/help/enrollment-verification-and-documentation`,
                studentEmailDomain: QUANTIC_STUDENT_EMAIL_DOMAIN,
                supportEmailDomain: VALAR_DOMAIN,
                defaultSupportEmailUsername: 'mba',
                helpScoutBeaconID: HELPSCOUT_BEACON_MBALM_ID,
                institutionID: InstitutionId.valar,
                numPrecedingMbaCohorts: 49, // NOTE: Both the MBA and EMBA have a demo cohort prior to MBA46/EMBA46/EMBASL46
                adminCohortCalendarColorDesaturation: 75,
                supportsValarOptIn: false,
                topMessageLocaleKeyPrefix: 'top_message_business_admin',
                studentNetworkQuickFilterConfigKeys: [
                    StudentNetworkQuickFilterKey.allMba,
                    StudentNetworkQuickFilterKey.allEmba,
                ],
                helpScoutArticleSiteId: ArticleSiteId.valar,
                gradingPolicyFaqUrl() {
                    // cycle 50-
                    if (this.startDate <= new Date(1673254800 * 1000)) {
                        // Older cohorts don't have an individual grading policy like the cohorts in more recent cycles,
                        // so we send them to the FAQ page covering the general overview of the grading policy as catchall.
                        return 'https://valar-support.quantic.edu/article/1355-grading-policy';
                    }

                    // cycle 51 - 55
                    if (this.startDate <= new Date(1694419200 * 1000)) {
                        return 'https://valar-support.quantic.edu/article/974-how-are-mba-grades-calculated-class-of-april-2024-and-beyond';
                    }

                    // cycle 56+
                    return 'https://valar-support.quantic.edu/article/1093-how-are-mba-grades-calculated-class-of-january-2025-and-beyond';
                },
            },
            {
                ...baseDegreeProgram,
                ...ProgramTypeConfigs[ProgramType.emba_strategic_leadership],
                key: ProgramType.emba_strategic_leadership,
                supportsExercises: true,
                supportsDelayedCareerNetworkAccessOnAcceptance: true,
                programAchievementGraphicProgramTitleLines: ['Executive MBA'],
                modifyPaymentDetailsModalProgramTitle: 'Executive MBA',
                acceptedScreenTitleHtml: 'Executive MBA',
                studentDashboardProgramBoxTitleHtml: `The ${formattedBrandName(
                    'short',
                    Brand.valar,
                )} <br class="hidden-xs hidden-sm"> EMBA`,
                applicationStatusAcceptedPrimaryMessageDefaultBrandName: formattedBrandName('long', Brand.valar),
                programTitle: `${formattedBrandName('short', Brand.valar)} EMBA`,
                programSwitcherMenuTitle: 'Executive Master of Business Administration in Strategic Leadership',
                transcriptSectionTitleDegree: 'EMBA',
                supportsIsolatedNetwork: true,
                enrollmentFaqLink: `/help/enrollment-verification-and-documentation`,
                studentEmailDomain: QUANTIC_STUDENT_EMAIL_DOMAIN,
                supportEmailDomain: VALAR_DOMAIN,
                defaultSupportEmailUsername: 'emba',
                helpScoutBeaconID: HELPSCOUT_BEACON_EMBASL_ID,
                institutionID: InstitutionId.valar,
                numPrecedingMbaCohorts: 45, // NOTE: Both the MBA and EMBA have a demo cohort prior to MBA46/EMBA46/EMBASL46
                adminCohortCalendarColorDesaturation: 25,
                supportsValarOptIn: false,
                topMessageLocaleKeyPrefix: 'top_message_business_admin',
                studentNetworkQuickFilterConfigKeys: [
                    StudentNetworkQuickFilterKey.allMba,
                    StudentNetworkQuickFilterKey.allEmba,
                ],
                helpScoutArticleSiteId: ArticleSiteId.valar,
                gradingPolicyFaqUrl() {
                    // cycle 50-
                    if (this.startDate <= new Date(1673254800 * 1000)) {
                        // Older cohorts don't have an individual grading policy like the cohorts in more recent cycles,
                        // so we send them to the FAQ page covering the general overview of the grading policy as catchall.
                        return 'https://valar-support.quantic.edu/article/1355-grading-policy';
                    }

                    // cycle 51 - 55
                    if (this.startDate <= new Date(1694419200 * 1000)) {
                        return 'https://valar-support.quantic.edu/article/973-how-are-emba-grades-calculated-class-of-april-2024-and-beyond';
                    }

                    // cycle 56+
                    return 'https://valar-support.quantic.edu/article/1092-how-are-emba-grades-calculated-class-of-january-2025-and-beyond';
                },
            },
            {
                ...baseDegreeProgram,
                ...ProgramTypeConfigs[ProgramType.msba],
                key: ProgramType.msba,
                programAchievementGraphicProgramTitleLines: ['Master of Science in', 'Business Analytics'],
                modifyPaymentDetailsModalProgramTitle: 'MS in Business Analytics',
                acceptedScreenTitleHtml: 'MS in Business Analytics',
                studentDashboardProgramBoxTitleHtml: `The ${formattedBrandName(
                    'short',
                    Brand.quantic,
                )} MS in <br class="hidden-xs hidden-sm"> Business Analytics`,
                applicationStatusAcceptedPrimaryMessageDefaultBrandName: formattedBrandName('short', Brand.quantic),
                programTitle: `${formattedBrandName('short', Brand.quantic)} MSBA`,
                programSwitcherMenuTitle: 'Master of Science in Business Analytics',
                transcriptSectionTitleDegree: 'MSBA',
                studentEmailDomain: QUANTIC_STUDENT_EMAIL_DOMAIN,
                supportEmailDomain: QUANTIC_DOMAIN,
                defaultSupportEmailUsername: 'msba',
                helpScoutBeaconID: HELPSCOUT_BEACON_HYBRID_ID, // FIXME -- change to final value
                institutionID: InstitutionId.quantic,
                numPrecedingMbaCohorts: 63,
                adminCohortCalendarColorDesaturation: 100,
                supportsValarOptIn: false,
                topMessageLocaleKeyPrefix: 'top_message_msba',
                studentNetworkQuickFilterConfigKeys: [StudentNetworkQuickFilterKey.allMsba],
                helpScoutArticleSiteId: ArticleSiteId.quantic,
                gradingPolicyFaqUrl() {
                    return 'https://support.quantic.edu/article/1500-how-are-msba-grades-calculated';
                },
            },
            {
                ...baseDegreeProgram,
                ...ProgramTypeConfigs[ProgramType.msse],
                key: ProgramType.msse,
                programAchievementGraphicProgramTitleLines: ['Master of Science in', 'Software Engineering'],
                modifyPaymentDetailsModalProgramTitle: 'MS in Software Engineering',
                acceptedScreenTitleHtml: 'MS in Software Engineering',
                studentDashboardProgramBoxTitleHtml: `The ${formattedBrandName(
                    'short',
                    Brand.quantic,
                )} MS in <br class="hidden-xs hidden-sm"> Software Engineering`,
                applicationStatusAcceptedPrimaryMessageDefaultBrandName: formattedBrandName('short', Brand.quantic),
                programTitle: `${formattedBrandName('short', Brand.quantic)} MSSE`,
                programSwitcherMenuTitle: 'Master of Science in Software Engineering',
                transcriptSectionTitleDegree: 'MSSE',
                studentEmailDomain: QUANTIC_STUDENT_EMAIL_DOMAIN,
                supportEmailDomain: QUANTIC_DOMAIN,
                defaultSupportEmailUsername: 'msse',
                helpScoutBeaconID: HELPSCOUT_BEACON_HYBRID_ID, // FIXME -- change to final value
                institutionID: InstitutionId.quantic,
                numPrecedingMbaCohorts: 63,
                adminCohortCalendarColorDesaturation: 50,
                supportsValarOptIn: false,
                topMessageLocaleKeyPrefix: 'top_message_msse',
                studentNetworkQuickFilterConfigKeys: [StudentNetworkQuickFilterKey.allMsse],
                helpScoutArticleSiteId: ArticleSiteId.quantic,
                gradingPolicyFaqUrl() {
                    return 'https://support.quantic.edu/article/1541-how-are-msse-grades-calculated';
                },
            },
            {
                ...baseVLACertificate,
                ...ProgramTypeConfigs[ProgramType.vla],
                key: ProgramType.vla,
                studentDashboardProgramBoxTitleHtml:
                    'The Valar <br class="hidden-xs hidden-sm"> Leadership Accelerator',
                programTitle: 'Valar Leadership Accelerator',
                programSwitcherMenuTitle: 'Valar Leadership Accelerator',
            },
            {
                ...baseVLACertificate,
                ...ProgramTypeConfigs[ProgramType.vla_aspiring_managers],
                key: ProgramType.vla_aspiring_managers,
                studentDashboardProgramBoxTitleHtml:
                    'The Valar Leadership Accelerator <br class="hidden-xs hidden-sm"> for Aspiring Leaders and Managers',
                programTitle: 'Valar Leadership Accelerator for Aspiring Leaders and Managers',
                programSwitcherMenuTitle: 'Valar Leadership Accelerator for Aspiring Leaders and Managers',
            },
            {
                key: ProgramType.career_network_only,
                ...ProgramTypeConfigs[ProgramType.career_network_only],
                programSwitcherMenuTitle: 'Careers Network Only',
                inDevelopment: () => false,
                supportsReapply: true,
                canPreviewCareerProfileAfterApplying: true,
                supportsRequiredWork: true,
                studentDashboardProgramBoxTitleHtml: 'Smartly <br> Talent',
                studentDashboardProgramBoxSubtitleKey: 'career_network_only',
                studentDashboardWelcomeBoxKey: 'career_network_only',
                studentDashboardProgramBoxShowProgressBar: false,
                learningBoxDescriptionKey: 'description_open_courses',
                learningBoxTitleKey: 'open_courses',
                showButtonKey: 'show_courses',
                applicationStatusPendingSecondaryMessageKey: 'pending_secondary_message_other',
                applicationStatusRejectedPrimaryMessageKey: 'rejected_primary_message_career_network_only',
                applicationStatusAcceptedPrimaryMessageKey: 'accepted_primary_message_career_network_only',
                programTitle() {
                    return this.title;
                },
                supportsAvatarUploadInAccountPage: true,
                supportsEditingCareerProfile: true,
                supportEmailDomain: SMARTLY_DOMAIN,
                defaultSupportEmailUsername: 'support',
                supportsValarOptIn() {
                    return false;
                },
                supportsResourcesTab: false,
                supportsCohortPreApproval: false,
                supportsWelcomeBox: true,
                helpScoutArticleSiteId: ArticleSiteId.quantic,
                supportsProgressReport: false,
            },
            {
                // External
                // This is the minimal config necessary (that I've identified so far) for an external
                // program type to function with a career profile. It's not necessarily intended to be
                // used long-term, but it's useful for now to stop the Applicant Admin -> Edit Profile
                // page from throwing errors when it looks up these keys.
                key: ProgramType.external,
                ...ProgramTypeConfigs[ProgramType.external],
                inDevelopment: () => false,
                supportsWelcomeBox: true,
                helpScoutArticleSiteId: ArticleSiteId.quantic,
            },
            // NOTE: The jordanian_math program type is new and we haven't quite flushed out all
            // of its configuration details yet. So, for now, we're just going to give it a minimal
            // config and we'll then come back later and properly configure it.
            getCertificateConfigForProgramType(ProgramType.jordanian_math, {
                // SMARTLY_DOMAIN is the default for supportEmailDomain in getCertificateConfigForProgramType,
                // but just adding it explicitly here to point out that that is intentional (though tbh I'm
                // not sure if this is actually displayed anywhere)
                supportEmailDomain: SMARTLY_DOMAIN,
                defaultSupportEmailUsername: 'support',
                supportsCompactPlaylistMap: true,
                studentDashboardProgramBoxSubtitleKey: 'jordanian_math',
                learningBoxDescriptionKey: 'description_jordanian_math',
                supportsAvatarUploadInAccountPage: false,
                supportsEditingCareerProfile: false,
                supportsAcceptanceMessage: false,
                // Signing into MiyaMiya falsely seems like a brand redirect and popping up the dialog
                // in this case doesn't make sense and is confusing to the user.
                confirmBrandRedirect: false,
                helpScoutArticleSiteId: ArticleSiteId.quantic,
            }),
            getCertificateConfigForProgramType(ProgramType.the_business_certificate, {
                programSwitcherMenuTitle: 'The Business Certificate',
                requiresEnglishLanguageProficiency: true,
                supportsAcceptanceMessage: true,
            }),
            getCertificateConfigForProgramType(ProgramType.tyb_ai_cert, {
                ...baseExecEdCertificate,
                programAchievementGraphicProgramTitleLines: ['Transform Your Business', 'with AI & ChatGPT'],
                programSwitcherMenuTitle: 'Transform Your Business with AI & ChatGPT',
                studentDashboardProgramBoxTitleHtml: 'Transform Your Business with AI & ChatGPT',
                marketingPageUrl: 'https://quantic.edu/executive-education/transform-your-business-with-ai-and-chatgpt',
            }),
            getCertificateConfigForProgramType(ProgramType.tyb_blockchain_cert, {
                ...baseExecEdCertificate,
                programAchievementGraphicProgramTitleLines: ['Transform Your Business', 'with Blockchain'],
                programSwitcherMenuTitle: 'Transform Your Business with Blockchain',
                studentDashboardProgramBoxTitleHtml: 'Transform Your Business with Blockchain',
                marketingPageUrl: 'https://quantic.edu/executive-education/transform-your-business-with-blockchain',
            }),
            // learn_code_gpt_cert has been retired. C-CODEAI-57 is the last cohort for this program type.
            getCertificateConfigForProgramType(ProgramType.learn_code_gpt_cert, {
                ...baseExecEdCertificate,
                programAchievementGraphicProgramTitleLines: ['Learn to Code', 'Using ChatGPT'],
                programSwitcherMenuTitle: 'Learn to Code Using ChatGPT',
                studentDashboardProgramBoxTitleHtml: 'Learn to Code Using ChatGPT',
                marketingPageUrl: 'https://quantic.edu/executive-education/learn-to-code-with-chatgpt',
            }),
            getCertificateConfigForProgramType(ProgramType.fin_for_non_fin_managers_cert, {
                ...baseExecEdCertificate,
                programAchievementGraphicProgramTitleLines: ['Finance & Accounting', 'for Managers'],
                programSwitcherMenuTitle: 'Finance & Accounting for Managers',
                studentDashboardProgramBoxTitleHtml: 'Finance & Accounting for Managers',
                marketingPageUrl: 'https://quantic.edu/executive-education/finance-and-accounting-for-managers',
            }),
            getCertificateConfigForProgramType(ProgramType.bus_analytics_leaders_cert, {
                ...baseExecEdCertificate,
                programAchievementGraphicProgramTitleLines: ['Business Analytics', 'for Leaders'],
                programSwitcherMenuTitle: 'Business Analytics for Leaders',
                studentDashboardProgramBoxTitleHtml: 'Business Analytics for Leaders',
                marketingPageUrl: 'https://quantic.edu/executive-education/business-analytics-for-leaders',
            }),
            getCertificateConfigForProgramType(ProgramType.data_science_foundations_cert, {
                ...baseExecEdCertificate,
                programAchievementGraphicProgramTitleLines: ['Data Science', 'Foundations'],
                programSwitcherMenuTitle: 'Data Science Foundations',
                studentDashboardProgramBoxTitleHtml: 'Data Science Foundations',
                marketingPageUrl: 'https://quantic.edu/executive-education/data-science-foundations',
            }),
            getCertificateConfigForProgramType(ProgramType.prototype_gpt_cert, {
                ...baseExecEdCertificate,
                programAchievementGraphicProgramTitleLines: ['AI for', 'Building Software'],
                programSwitcherMenuTitle: 'AI for Building Software',
                studentDashboardProgramBoxTitleHtml: 'AI for Building Software',
                marketingPageUrl: 'https://quantic.edu/executive-education/ai-for-building-software',
            }),
            getCertificateConfigForProgramType(ProgramType.cto_cert, {
                ...baseExecEdCertificate,
                programAchievementGraphicProgramTitleLines: ['Chief Technology Officer', 'Program'],
                programSwitcherMenuTitle: 'CTO Program',
                studentDashboardProgramBoxTitleHtml: 'The Quantic CTO Program',
                marketingPageUrl: 'https://quantic.edu/executive-education/quantic-cto-program',
            }),
            getCertificateConfigForProgramType(ProgramType.founders_cert, {
                ...baseExecEdCertificate,
                programAchievementGraphicProgramTitleLines: ['Founders Program'],
                programSwitcherMenuTitle: 'Founders Program',
                studentDashboardProgramBoxTitleHtml: 'The Quantic Founders Program',
                marketingPageUrl: 'https://quantic.edu/executive-education/quantic-founders-program',
            }),
            getCertificateConfigForProgramType(ProgramType.executive_cert, {
                ...baseExecEdCertificate,
                programAchievementGraphicProgramTitleLines: ['Executive Program'],
                programSwitcherMenuTitle: 'Executive Program',
                studentDashboardProgramBoxTitleHtml: 'The Quantic Executive Program',
                marketingPageUrl: 'https://quantic.edu/executive-education/quantic-executive-program',
            }),
            getCertificateConfigForProgramType(ProgramType.cmo_cert, {
                ...baseExecEdCertificate,
                programAchievementGraphicProgramTitleLines: ['Chief Marketing Officer', 'Program'],
                programSwitcherMenuTitle: 'CMO Program',
                studentDashboardProgramBoxTitleHtml: 'The Quantic CMO Program',
                marketingPageUrl: 'https://quantic.edu/executive-education/quantic-cmo-program',
            }),
            getCertificateConfigForProgramType(ProgramType.ai_for_technical_leaders_cert, {
                ...baseExecEdCertificate,
                programAchievementGraphicProgramTitleLines: ['AI for', 'Technical Leaders'],
                programSwitcherMenuTitle: 'AI for Technical Leaders',
                studentDashboardProgramBoxTitleHtml: 'AI for Technical Leaders',
                marketingPageUrl: 'https://quantic.edu/executive-education/ai-for-technical-leaders',
            }),
        ];

        // eslint-disable-next-line complexity
        function getCertificateConfigForProgramType(programType, opts) {
            CERTIFICATE_PROGRAM_TYPES.push(programType);

            // ProgramTypeConfigs[programType] has to be imported into both `opts` and the
            // return object. That way anything that's checking `opts` will be able to use
            // the property that has been passed in.
            opts = {
                ...opts,
                ...ProgramTypeConfigs[programType],
            };

            return {
                ...ProgramTypeConfigs[programType],
                inDevelopment: opts.inDevelopment ?? (() => false),
                supportEmailDomain: opts.supportEmailDomain ?? SMARTLY_DOMAIN,
                defaultSupportEmailUsername: 'support',
                icon: opts.icon,
                marketingPageUrl: opts.marketingPageUrl,
                supportsAcceptanceMessage: !!opts.supportsAcceptanceMessage,
                supportsCertificateDownload: true,
                supportsGradebook: opts.supportsGradebook ?? false,
                supportsReapply: true,
                supportsAutograding: true,
                supportsSpecializations: opts.supportsSpecializations ?? false,
                canPreviewCareerProfileAfterApplying: true,
                excludesFoundationsPlaylistOnAcceptance: !!opts.excludesFoundationsPlaylistOnAcceptance,
                learningBoxDescriptionKey: opts.learningBoxDescriptionKey || 'description_certificates',
                learningBoxTitleKey: 'certificates_curriculum',
                requiresEnglishLanguageProficiency: !!opts.requiresEnglishLanguageProficiency,
                acceptedScreenTitleHtml() {
                    const upcased = this.title.toUpperCase();

                    if (upcased.endsWith('CERTIFICATE')) {
                        return upcased.replace('CERTIFICATE', '<br class="hidden-xs">CERTIFICATE');
                    }
                    return upcased;
                },
                studentDashboardProgramBoxTitleHtml() {
                    return opts.studentDashboardProgramBoxTitleHtml ?? this.title;
                },
                studentDashboardProgramBoxSubtitleKey: opts.studentDashboardProgramBoxSubtitleKey || 'certificates',
                studentDashboardWelcomeBoxKey: 'certificates',
                studentDashboardProgramBoxShowProgressBar: false,
                programAchievementGraphicProgramTitleLines: opts.programAchievementGraphicProgramTitleLines ?? [],
                showButtonKey: 'show_curriculum',
                applicationStatusPendingSecondaryMessageKey: 'pending_secondary_message_other',
                applicationStatusRejectedPrimaryMessageKey: 'rejected_primary_message_certificates',

                // FIXME: Remove and change the base messageKey after enableQuantic flag is flipped
                // See https://trello.com/c/QFVa6l5W
                applicationStatusRejectedPrimaryMessageKeyEnableQuantic:
                    'rejected_primary_message_certificates_enable_quantic',

                applicationStatusAcceptedPrimaryMessageKey: 'accepted_primary_message_default',
                applicationStatusAcceptedPrimaryMessageDefaultBrandName: formattedBrandName('short', opts.branding),
                programTitle() {
                    return this.title;
                },
                programSwitcherMenuTitle: opts.programSwitcherMenuTitle ?? opts.internalLabel,
                transcriptProgramTitle: opts.transcriptProgramTitle ?? opts.internalLabel,
                transcriptSectionTitleDegree: opts.transcriptSectionTitleDegree ?? opts.internalLabel,
                supportsCompactPlaylistMap: opts.supportsCompactPlaylistMap ?? false,
                supportsAvatarUploadInAccountPage: opts.supportsAvatarUploadInAccountPage ?? true,
                supportsEditingCareerProfile: opts.supportsEditingCareerProfile ?? true,
                supportsValarOptIn() {
                    return false;
                },
                confirmBrandRedirect: opts.confirmBrandRedirect ?? false,
                supportsResourcesTab: opts.supportsResourcesTab ?? false,
                helpScoutArticleSiteId: opts.helpScoutArticleSiteId ?? ArticleSiteId.quantic,
                studentNetworkQuickFilterConfigKeys: opts.studentNetworkQuickFilterConfigKeys ?? [],
                isExecEd: opts.isExecEd ?? false,
                supportsAdmissionRounds: opts.supportsAdmissionRounds ?? false,
                supportsEnrollmentAgreement: opts.supportsEnrollmentAgreement ?? false,
                supportsEnrollmentDocumentsDeadline: opts.supportsEnrollmentDocumentsDeadline ?? false,
                supportsEnrollmentDeadline: opts.supportsEnrollmentDeadline ?? false,
                supportsEnrollmentSidebar: opts.supportsEnrollmentSidebar ?? false,
                enrollmentFaqLink: opts.enrollmentFaqLink,
                welcomePackageConfiguration: opts.welcomePackageConfiguration ?? {},
                supportsDocumentUpload: opts.supportsDocumentUpload ?? false,
                requiresMailingAddress: opts.requiresMailingAddress ?? false,
                // FIXME: this is just a fallback and probably not what we'd like to use for the final value
                numPrecedingMbaCohorts: opts.numPrecedingMbaCohorts ?? 0,
                // FIXME: this is just a fallback and probably not what we'd like to use for the final value
                adminCohortCalendarColorDesaturation: opts.adminCohortCalendarColorDesaturation ?? 25,
                supportsCohortPreApproval: opts.supportsCohortPreApproval ?? false,
                supportsWelcomeBox: opts.supportsWelcomeBox ?? true,
                hasOrientation: opts.hasOrientation ?? false,
                supportsProgressReport: opts.supportsProgressReport ?? false,
            };
        }

        // for performance, make it accessible via the keys as well
        _.chain(programTypes)
            .map('key')
            .forEach((key, i) => {
                programTypes[key] = programTypes[i];
            })
            .value();

        // For program types that support admission rounds, the promoted cohort
        // is determined dynamically based on when rounds are ending.  For other
        // program types, we select one to be promoted.
        const promotableProgramTypes = _.reject(
            programTypes,
            programType => programType.supportsAdmissionRounds === true || programType.key === ProgramType.external,
        );

        // Program types that should show up on the calendar, i.e.: they have schedules
        const calendarProgramTypes = _.filter(programTypes, programType => programType.supportsSchedule);

        function delegateToProgramType(target, prop, type) {
            const getter = (programType, schedulable) => {
                const val = programTypes[programType] && programTypes[programType][prop];
                if (typeof val === 'function') {
                    if (!schedulable) {
                        throw new Error(
                            `${prop} cannot be called as a class method since it is defined as a function.`,
                        );
                    }
                    return val.call(schedulable);
                }
                if (type === 'bool') {
                    return !!val;
                }
                return val;
            };

            target[prop] = programType => getter(programType);

            Object.defineProperty(target.prototype, prop, {
                get() {
                    return getter(this.program_type, this);
                },
                configurable: true,
            });
        }

        return new AModuleAbove({
            included(target) {
                target.include(SupportsRelativeDates);
                target.CERTIFICATE_PROGRAM_TYPES = CERTIFICATE_PROGRAM_TYPES;
                target.embedsMany('groups', 'Group');
                target.embedsMany('periods', 'Period');
                target.embedsMany('admission_rounds', 'AdmissionRound');
                target.embedsMany('id_verification_periods', 'IdVerificationPeriod');

                target.programTypes = programTypes;
                target.promotableProgramTypes = promotableProgramTypes;
                target.calendarProgramTypes = calendarProgramTypes;
                target.businessAdminProgramTypes = programTypes.filter(config => config.isMBA || config.isEMBA);

                target.setCallback('before', 'save', function () {
                    // re-order admission_rounds and id_verification_periods by their deadlines so they will have the proper index
                    if (this.admission_rounds && this.admission_rounds.length > 1) {
                        this.admission_rounds = _.sortBy(this.admission_rounds, 'applicationDeadline');
                    }
                    if (this.id_verification_periods && this.id_verification_periods.length > 1) {
                        this.id_verification_periods = _.sortBy(this.id_verification_periods, 'dueDate');
                    }
                });
                //
                target.setCallback('after', 'copyAttrsOnInitialize', function () {
                    this.admission_rounds = this.admission_rounds || [];
                    this._setDefaultEnrollmentDeadline();

                    this.id_verification_periods = this.id_verification_periods || [];
                    this.playlist_collections = this.playlist_collections || [];
                });

                target.defineSetter('program_type', function (val) {
                    this.writeKey('program_type', val);

                    if (!this.supportsSchedule) {
                        this.periods = [];
                    }

                    if (!this.supportsSpecializations) {
                        this.num_required_specializations = 0;
                        this.specialization_playlist_pack_ids = [];
                    }

                    if (!this.supportsAdmissionRounds) {
                        this.admission_rounds = [];
                    } else if (this.admission_rounds.length === 0) {
                        this.addAdmissionRound();
                    }

                    if (!this.supportsIdVerificationPeriods) {
                        this.id_verification_periods = [];
                    }

                    this._setDefaultEnrollmentDeadline();
                    this.setDefaultEnrollmentDocumentsDeadline();
                    this.setDefaultOfficialTranscriptsDeadline();
                });

                target.programTypesForInstitutionId = function programTypesForInstitutionId(institutionId) {
                    return programTypes.filter(programType => programType.institutionID === institutionId);
                };

                target.supportEmailAddress = function supportEmailAddress(programType, username) {
                    username = username || this.defaultSupportEmailUsername(programType);
                    return `${username}@${this.supportEmailDomain(programType)}`;
                };

                // This is for debugging. It will find all of the unique combinations of the provided keys
                // and tells you which program types have that combination.
                // Ex: $('[ng-controller]').injector().get('Cohort').audit(['supportsEnrollmentAgreement', 'supportsSchedule'])
                target.audit = function (keys) {
                    const map = {};

                    programTypes.forEach(entry => {
                        const programType = entry.key;
                        const valsForProgramType = keys.reduce((acc, key) => {
                            const fn = target[key];
                            let val;
                            if (typeof fn === 'function') {
                                try {
                                    val = fn(programType);
                                } catch (err) {
                                    if (!err.message.match(/cannot be called as a class method since it is define/)) {
                                        throw err;
                                    }
                                }
                            }
                            if (val === undefined) {
                                val = programTypes[programType][key];
                            }

                            // When I was comparing shifts to ProgramTypeCnfig.ts, sometimes things that
                            // used to be undefined changed to ''. Convenient to ignore these
                            if (val === '') {
                                val = undefined;
                            }

                            acc[key] = val;

                            return acc;
                        }, {});
                        const serialized = JSON.stringify(valsForProgramType);
                        map[serialized] = map[serialized] || [];
                        map[serialized].push(entry.key);
                        map[serialized] = map[serialized].sort();
                    });

                    return map;
                };

                delegateToProgramType(target, 'inDevelopment');
                delegateToProgramType(target, 'branding');
                delegateToProgramType(target, 'supportEmailDomain');
                delegateToProgramType(target, 'defaultSupportEmailUsername');
                delegateToProgramType(target, 'isMBA', 'bool');
                delegateToProgramType(target, 'isEMBA', 'bool');
                delegateToProgramType(target, 'isMSBA', 'bool');
                delegateToProgramType(target, 'isMSSE', 'bool');
                delegateToProgramType(target, 'isExecEd', 'bool');
                delegateToProgramType(target, 'supportsAdmissionRounds', 'bool');
                delegateToProgramType(target, 'supportsSchedule', 'bool');
                delegateToProgramType(target, 'supportsGradebook', 'bool');
                delegateToProgramType(target, 'supportsEnrollmentDeadline', 'bool');
                delegateToProgramType(target, 'supportsDeferralLink', 'bool');
                delegateToProgramType(target, 'supportsDocumentUpload', 'bool');
                delegateToProgramType(target, 'supportedDeprecatedIdUpload', 'bool');
                delegateToProgramType(target, 'supportsAcceptanceMessage', 'bool');
                delegateToProgramType(target, 'supportsCertificateDownload', 'bool');
                delegateToProgramType(target, 'supportsReapply', 'bool');
                delegateToProgramType(target, 'supportsAutograding', 'bool');
                delegateToProgramType(target, 'supportsSpecializations', 'bool');
                delegateToProgramType(target, 'canPreviewCareerProfileAfterApplying', 'bool');
                delegateToProgramType(target, 'excludesFoundationsPlaylistOnAcceptance', 'bool');
                delegateToProgramType(target, 'supportsExercises', 'bool');
                delegateToProgramType(target, 'supportsNetworkAccess', 'bool');
                delegateToProgramType(target, 'studentDashboardProgramBoxShowProgressBar', 'bool');
                delegateToProgramType(target, 'supportsSlackRooms', 'bool');
                delegateToProgramType(target, 'supportsDelayedCareerNetworkAccessOnAcceptance', 'bool');
                delegateToProgramType(target, 'canFilterNetworkForMyClass', 'bool');
                delegateToProgramType(target, 'isDegreeProgram', 'bool');
                delegateToProgramType(target, 'requiresEnglishLanguageProficiency', 'bool');
                delegateToProgramType(target, 'requiresMailingAddress', 'bool');
                delegateToProgramType(target, 'learningBoxDescriptionKey');
                delegateToProgramType(target, 'learningBoxTitleKey');
                delegateToProgramType(target, 'showButtonKey');
                delegateToProgramType(target, 'studentDashboardProgramBoxSubtitleKey');
                delegateToProgramType(target, 'applicationStatusRejectedPrimaryMessageKey');
                delegateToProgramType(target, 'applicationStatusRejectedAfterPreAcceptedPrimaryMessageKey');
                delegateToProgramType(target, 'applicationStatusRejectedPrimaryMessageKeyCantReapply');
                delegateToProgramType(target, 'applicationStatusRejectedAfterPreAcceptedPrimaryMessageKeyCantReapply');
                delegateToProgramType(target, 'applicationStatusPendingSecondaryMessageKey');
                delegateToProgramType(target, 'applicationStatusAcceptedPrimaryMessageKey');
                delegateToProgramType(target, 'applicationStatusAcceptedPrimaryMessageDefaultBrandName');
                delegateToProgramType(target, 'icon');
                delegateToProgramType(target, 'marketingPageUrl');
                delegateToProgramType(target, 'studentDashboardWelcomeBoxKey');
                delegateToProgramType(target, 'shortProgramTitle');
                delegateToProgramType(target, 'networkProgramTitle');
                delegateToProgramType(target, 'fullTitle');
                delegateToProgramType(target, 'studentDashboardProgramBoxTitleHtml');
                delegateToProgramType(target, 'programAchievementGraphicProgramTitleLines');
                delegateToProgramType(target, 'modifyPaymentDetailsModalProgramTitle');
                delegateToProgramType(target, 'acceptedScreenTitleHtml');
                delegateToProgramType(target, 'programTitle');
                delegateToProgramType(target, 'programSwitcherMenuTitle');
                delegateToProgramType(target, 'transcriptProgramTitle');
                delegateToProgramType(target, 'transcriptSectionTitleDegree');
                delegateToProgramType(target, 'supportsEnrollmentSidebar', 'bool');
                delegateToProgramType(target, 'supportsEnrollmentAgreement', 'bool');
                delegateToProgramType(target, 'supportsPresentationProjects', 'bool');
                delegateToProgramType(target, 'supportsCompactPlaylistMap', 'bool');
                delegateToProgramType(target, 'supportsAvatarUploadInAccountPage', 'bool');
                delegateToProgramType(target, 'supportsEditingCareerProfile', 'bool');
                delegateToProgramType(target, 'supportsIsolatedNetwork', 'bool');
                delegateToProgramType(target, 'enrollmentFaqLink');
                delegateToProgramType(target, 'welcomePackageConfiguration');
                delegateToProgramType(target, 'studentEmailDomain');
                delegateToProgramType(target, 'supportsEnrollmentDocumentsDeadline', 'bool');
                delegateToProgramType(target, 'supportsOfficialTranscriptsDeadline', 'bool');
                delegateToProgramType(target, 'helpScoutBeaconID');
                delegateToProgramType(target, 'institutionID');
                delegateToProgramType(target, 'numPrecedingMbaCohorts');
                delegateToProgramType(target, 'adminCohortCalendarColorDesaturation');
                delegateToProgramType(target, 'supportsValarOptIn');
                delegateToProgramType(target, 'confirmBrandRedirect', 'bool');
                delegateToProgramType(target, 'supportsResourcesTab', 'bool');
                delegateToProgramType(target, 'topMessageLocaleKeyPrefix');
                delegateToProgramType(target, 'studentNetworkQuickFilterConfigKeys');
                delegateToProgramType(target, 'helpScoutArticleSiteId');
                delegateToProgramType(target, 'supportsCohortPreApproval', 'bool');
                delegateToProgramType(target, 'supportsWelcomeBox', 'bool');
                delegateToProgramType(target, 'hasOrientation', 'bool');
                delegateToProgramType(target, 'completionDocumentType');
                delegateToProgramType(target, 'standaloneShortProgramTitle');
                delegateToProgramType(target, 'programFamily');
                delegateToProgramType(target, 'applicationFormConfigs');
                delegateToProgramType(target, 'gradingPolicyFaqUrl');

                Object.defineProperty(target.prototype, 'programType', {
                    get() {
                        return this.program_type;
                    },
                });
                delegateToProgramType(target, 'supportsProgressReport', 'bool');

                // This is not actually saved to the db, but is calculated on-the-fly.
                // Could make smarter use of caching if this property become used a lot.
                Object.defineProperty(target.prototype, 'endDate', {
                    get() {
                        const computed = this.computeEndDate(this.startDate.getTime(), this.periods);
                        if (this.$$endDate && !moment(this.$$endDate).isSame(computed)) {
                            this.$$endDate = null;
                        }

                        if (!this.$$endDate) {
                            this.$$endDate = computed.toDate();
                        }

                        return this.$$endDate;
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, 'registrationDeadline', {
                    get() {
                        return this._getCachedRelativeDate('registrationDeadline', 'registration_deadline_days_offset');
                    },
                    set(date) {
                        this.registration_deadline_days_offset = this._getRelativeDayOffset(date);
                    },
                });

                Object.defineProperty(target.prototype, 'earlyRegistrationDeadline', {
                    get() {
                        return this._getCachedRelativeDate(
                            'earlyRegistrationDeadline',
                            'early_registration_deadline_days_offset',
                        );
                    },
                    set(date) {
                        this.early_registration_deadline_days_offset = this._getRelativeDayOffset(date);
                    },
                });

                Object.defineProperty(target.prototype, 'autoDeclineAdmissionOffersDate', {
                    get() {
                        return this._getCachedRelativeDate(
                            'autoDeclineAdmissionOffersDate',
                            'auto_decline_admission_offers_days_offset',
                        );
                    },
                    set(date) {
                        this.auto_decline_admission_offers_days_offset = this._getRelativeDayOffset(date);
                    },
                });

                Object.defineProperty(target.prototype, 'activateExecEdForUnadmittedUsersDate', {
                    get() {
                        return this._getCachedRelativeDate(
                            'activateExecEdForUnadmittedUsersDate',
                            'activate_exec_ed_for_unadmitted_users_days_offset',
                        );
                    },
                    set(date) {
                        this.activate_exec_ed_for_unadmitted_users_days_offset = this._getRelativeDayOffset(date);
                    },
                });

                Object.defineProperty(target.prototype, 'activateExecEdForFormerStudentsDate', {
                    get() {
                        return this._getCachedRelativeDate(
                            'activateExecEdForFormerStudentsDate',
                            'activate_exec_ed_for_former_students_days_offset',
                        );
                    },
                    set(date) {
                        this.activate_exec_ed_for_former_students_days_offset = this._getRelativeDayOffset(date);
                    },
                });

                Object.defineProperty(target.prototype, 'enrollmentDeadline', {
                    get() {
                        return this._getCachedRelativeDate('enrollmentDeadline', 'enrollment_deadline_days_offset');
                    },
                    set(date) {
                        this.enrollment_deadline_days_offset = this._getRelativeDayOffset(date);
                    },
                });

                Object.defineProperty(target.prototype, 'enrollmentDocumentsDeadline', {
                    get() {
                        return this._getCachedRelativeDate(
                            'enrollmentDocumentsDeadline',
                            'enrollment_documents_deadline_days_offset',
                        );
                    },
                    set(date) {
                        this.enrollment_documents_deadline_days_offset = this._getRelativeDayOffset(date);
                    },
                });

                Object.defineProperty(target.prototype, 'officialTranscriptsDeadline', {
                    get() {
                        return this._getCachedRelativeDate(
                            'officialTranscriptsDeadline',
                            'official_transcripts_deadline_days_offset',
                        );
                    },
                    set(date) {
                        this.official_transcripts_deadline_days_offset = this._getRelativeDayOffset(date);
                    },
                });

                // For cohorts where we generate a diploma or certificate at a specific time,
                // the UI needs to know what that is in order to make certain messaging dynamic. Search
                // for diplomaGenerationDate in the codebase to see where this is used.
                //
                // This is misnamed now. It's not always a diploma. Sometimes it's a digital
                // certificate. But, doesn't seem worth renaming the column and all, so we'll
                // stick with a consistent, incorrect name.
                Object.defineProperty(target.prototype, 'supportsDiplomaGenerationDate', {
                    get() {
                        return [DELIVERED_DIGITAL_CERTIFICATE, PHYSICAL_DIPLOMA].includes(this.completionDocumentType);
                    },
                    configurable: true,
                });

                // See comment above supportsDiplomaGenerationDate about the naming of this property.
                Object.defineProperty(target.prototype, 'diplomaGenerationDate', {
                    get() {
                        return this._getCachedRelativeDate(
                            'diplomaGenerationDate',
                            'diploma_generation_days_offset_from_end',
                            this.endDate,
                        );
                    },
                    set(date) {
                        this.diploma_generation_days_offset_from_end = this._getRelativeDayOffset(date, this.endDate);
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, 'supportsPhysicalDiploma', {
                    get() {
                        return this.completionDocumentType === PHYSICAL_DIPLOMA;
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, 'supportsDeliveredDigitalCertificate', {
                    get() {
                        return this.completionDocumentType === DELIVERED_DIGITAL_CERTIFICATE;
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, 'supportsDynamicDownloadableCertificate', {
                    get() {
                        return this.completionDocumentType === DYNAMIC_DOWNLOADABLE_CERTIFICATE;
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, 'supportsCertificate', {
                    get() {
                        return this.supportsDeliveredDigitalCertificate || this.supportsDynamicDownloadableCertificate;
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, 'refersToGraduation', {
                    get() {
                        // We talk about "graduation" in programs that issue a diploma, but about "certificate completion"
                        // or "program completion" in programs that do not
                        return this.completionDocumentType === PHYSICAL_DIPLOMA;
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, 'graduationDate', {
                    get() {
                        return this._getCachedRelativeDate(
                            'graduationDate',
                            'graduation_days_offset_from_end',
                            this.endDate,
                        );
                    },
                    set(date) {
                        this.graduation_days_offset_from_end = this._getRelativeDayOffset(date, this.endDate);
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, 'supportsIdVerificationPeriods', {
                    get() {
                        return this.supportsSchedule;
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, 'supportsPayments', {
                    get() {
                        return this.available_tuition_plan_ids?.length > 0;
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, 'supportsRegistrationDeadline', {
                    get() {
                        return !!(this.supportsPayments && this.supportsSchedule);
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, 'supportsEarlyRegistrationDeadline', {
                    get() {
                        // EMBA 29+ does not support early registration
                        return !!(this.supportsRegistrationDeadline && this.startDate < new Date('2020/10/19'));
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, 'supportsScholarshipLevels', {
                    get() {
                        return this.available_scholarship_ids?.length > 0;
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, 'supportsProgramGuide', {
                    get() {
                        return !!(this.isDegreeProgram && this.supportsPayments);
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, 'supportsAcceptanceMessageOnApplicationPage', {
                    get() {
                        return !!(this.isDegreeProgram && this.supportsPayments);
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, '_startDateForRelativeDates', {
                    get() {
                        return this.startDate;
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, 'supportsNewPriceChange', {
                    get() {
                        // only cycle 48+ supports the new price change
                        return this.startDate >= new Date('2022/10/17');
                    },
                    configurable: true,
                });

                // FIXME: this is duplicated in Cohort.ts, but we need to make a base cohort type to fix it
                Object.defineProperty(target.prototype, 'requiresProctoring', {
                    get() {
                        return (
                            ConfigFactory.getSync().biosigEnabled() &&
                            !!programTypes[this.program_type]?.requiresProctoring &&
                            // We started requiring proctoring in cycle 54.
                            // Compare against UTC time because the start date of
                            // Mon, 14 Aug 2023 08:00:00 UTC
                            // is Sun Aug 13 2023 22:00:00 GMT-1000 in Hawaii-Aleutian Standard Time
                            this.startDate >= new Date('2023-08-14T08:00:00Z')
                        );
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, 'preferStrictSmartcaseScore', {
                    get() {
                        return this.startDate >= new Date(1697443200 * 1000); // cycle 56+
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, 'hasPlaylistCollections', {
                    get() {
                        return this.playlist_collections.length > 0;
                    },
                });

                Object.defineProperty(target.prototype, 'concentrationPlaylistPackIds', {
                    get() {
                        // See comment in cohort.rb#concentration_pack_ids_from_playlist_collections about
                        // naming inconsistency with concentrationPlaylistPackIds vs. required_playlist_pack_ids
                        return _.reduce(
                            this.playlist_collections,
                            (memo, playlistCollection) => memo.concat(playlistCollection.required_playlist_pack_ids),
                            [],
                        );
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, 'playlistPackIds', {
                    get() {
                        return this.concentrationPlaylistPackIds.concat(this.specialization_playlist_pack_ids);
                    },
                    configurable: true, // for specs
                });

                /* This is temporary hardcoded logic to unblock development before we have nominations dates editable
                   for curriculum templates and cohorts via their editor UIs in https://trello.com/c/7HL06Rua */
                Object.defineProperty(target.prototype, 'supportsNominationsTab', {
                    get() {
                        const config = ConfigFactory.getSync();
                        const pilotCohorts = ['EMBA54', 'EMBA46', 'MBA54', 'MBA46'];
                        const isPastLaunchDate = new Date() > new Date('2023-12-01');

                        if (config.disableNominationsTab()) return false;
                        if (['development', 'staging'].includes(config.appEnvType())) return true;
                        // production
                        return pilotCohorts.includes(this.name) && isPastLaunchDate;
                    },
                    configurable: true,
                });

                /* This is temporary hardcoded logic to unblock development before we have nominations dates editable
                   for curriculum templates and cohorts via their editor UIs in https://trello.com/c/7HL06Rua */
                Object.defineProperty(target.prototype, 'nominationsState', {
                    get() {
                        if (!this.supportsNominationsTab) return null;
                        if (new Date() < new Date('2023/12/04 4:00')) return NominationsState.preNomination;
                        if (new Date() < new Date('2024/01/01 4:00')) return NominationsState.duringNomination;
                        return NominationsState.postNomination;
                    },
                    configurable: true,
                });
            },

            ensureLessThanOrEqualTo(x, y, message) {
                if (x <= y) {
                    return true;
                }
                ngToast.create({
                    content: message,
                    className: 'warning',
                });
                return false;
            },

            ensureGreaterThanOrEqualTo(x, y, message) {
                if (x >= y) {
                    return true;
                }
                ngToast.create({
                    content: message,
                    className: 'warning',
                });
                return false;
            },

            addAdmissionRound() {
                const attrs = {};
                if (this.admission_rounds.length === 0) {
                    attrs.application_deadline_days_offset = -14;
                } else {
                    attrs.application_deadline_days_offset =
                        _.first(this.admission_rounds).application_deadline_days_offset - 21;
                }

                attrs.decision_date_days_offset = attrs.application_deadline_days_offset + 9;

                const admissionRound = AdmissionRound.new(attrs);
                admissionRound.$$embeddedIn = this;
                this.admission_rounds.unshift(admissionRound);
                return admissionRound;
            },

            addIdVerificationPeriod() {
                const attrs = {};

                if (this.id_verification_periods.length === 0) {
                    attrs.start_date_days_offset = 0;
                } else {
                    attrs.start_date_days_offset = _.last(this.id_verification_periods).due_date_days_offset + 21;
                }

                attrs.due_date_days_offset = attrs.start_date_days_offset + 7;

                const idVerificationPeriod = IdVerificationPeriod.new(attrs);
                idVerificationPeriod.$$embeddedIn = this;
                this.id_verification_periods.push(idVerificationPeriod);
                return idVerificationPeriod;
            },

            /**
             * Computes an end date
             * @param  {integer} startTimestamp The startDate expressed as a timestamp in milliseconds
             * @param  {Array} periods The schedulableItem's periods
             * @return {Moment} A moment object representing the schedulableItem's endDate
             */
            computeEndDate(startTimestamp, periods) {
                let span = 0;
                _.forEach(periods, period => {
                    span += period.days + period.days_offset;
                });
                return dateHelper.addInDefaultTimeZone(moment(startTimestamp), span, 'days');
            },

            computePeriodDates() {
                // Start with the schedulable item's startDate
                // (Feels like a bug in the calendar widget that we have to set the timezone
                // here and in the options above)
                let rollingDate = moment(this.startDate);

                // Loop through each period and compute the start and end dates based on the duration and offset
                _.forEach(this.periods, period => {
                    rollingDate = dateHelper.addInDefaultTimeZone(rollingDate, period.days_offset, 'days'); // add the offset
                    // the easiest way to get periods to show up cleanly in the calendar
                    // is to have all of them start and end on day boundaries to prevent
                    // overlap. See comment above about nextDayThreshold
                    period.startDate = rollingDate.toDate();
                    rollingDate = dateHelper.addInDefaultTimeZone(rollingDate, period.days, 'days'); // add the duration

                    period.endDate = rollingDate.toDate();
                });

                if (this.persistsEndDate) {
                    this.end_date = this.endDate.getTime() / 1000;
                }
            },

            computeDefaultStartDate() {
                // Get next Monday at 12:00 AM
                let nextMonday = new Date();
                nextMonday.setDate(nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7 || 7)); // http://stackoverflow.com/a/33078673/1747491
                nextMonday.setHours(0, 0, 0, 0);

                // Shift timezone
                nextMonday = dateHelper.shiftDateToTimezone(nextMonday, 'America/New_York');

                // Add four hours
                nextMonday = moment(nextMonday).add(4, 'hours').toDate();

                return nextMonday;
            },

            periodForRequiredStream(localePackId) {
                return _.find(this.periods, period => !!period.requiresStream(localePackId)) || null;
            },

            addWinterBreakPeriods() {
                // This method adds two consecutive periods of 7 days each, starting on
                // Monday of Christmas week and ending Sunday of New Year's week. It will
                // potentially do this multiple times, in cases where a cohort spans the
                // Winter break period of multiple years.
                //
                // Because Christmas and New Year's Day are 7 days apart (and we don't
                // expect the Gregorian calendar to add/remove days between December 25th
                // and January 1st any time soon), we can safely assume that the two
                // periods of a particular Winter break  will not overlap and we can just
                // use Christmas' date to determine all the other dates.

                // Remove any existing Winter break periods.
                this.periods = this.periods.filter(period => !period.isWinterBreakPeriod);

                // Get all the years that the cohort spans.
                const cohortStartYear = moment(this.startDate).year();
                const cohortEndYear = moment(this.endDate).year();
                const cohortYears = [...Array(cohortEndYear - cohortStartYear + 1).keys()].map(
                    year => year + cohortStartYear,
                );

                // For each year, add the two Winter break periods if the cohort extends
                // into the Winter break period of that year.
                cohortYears.forEach(year => {
                    // Grabbing this year's Christmas day to hinge our winter break
                    // periods off of.
                    const christmasDate = moment().year(year).month('December').date(25);

                    // The start of the first break period should be the Monday of Christmas week.
                    // Use moment's isoWeekday() method since our weeks start on Monday.
                    const winterBreakStart = moment(christmasDate).isoWeekday(1);

                    // Skip adding the Winter break periods if the cohort ends before the
                    // start of this year's Winter break period.
                    if (moment(this.endDate).isSameOrBefore(winterBreakStart)) {
                        return;
                    }

                    // Determine placement and add the two Winter break periods for this year.
                    const winterBreakPeriodIndex =
                        this.periods.findLastIndex(period => moment(period.endDate).isSameOrBefore(winterBreakStart)) +
                        1;

                    const winterBreakPeriods = [
                        {
                            title: `**Week ${winterBreakPeriodIndex + 1}: Winter Break**`,
                            days: 7,
                            style: 'break',
                            startDate: winterBreakStart.toDate(),
                        },
                        {
                            title: `**Week ${winterBreakPeriodIndex + 2}: Winter Break**`,
                            days: 7,
                            style: 'break',
                            startDate: moment(winterBreakStart).add(7, 'days').toDate(),
                        },
                    ].map(period => {
                        const periodInstance = Period.new(period);
                        periodInstance.$$embeddedIn = this;
                        return periodInstance;
                    });

                    this.periods.splice(winterBreakPeriodIndex, 0, ...winterBreakPeriods);
                });

                this.renumberPeriods();
            },

            addPeriod(index) {
                index = angular.isDefined(index) ? index : this.periods.length;
                const period = Period.new();
                period.$$embeddedIn = this;
                this.periods.splice(index, 0, period);
                this.renumberPeriods();
            },

            removePeriod(period) {
                this.periods = _.without(this.periods, period);
                this.renumberPeriods();
            },

            renumberPeriods() {
                this.periods.forEach((period, index) => {
                    // The following regex will match period titles following a 'Week 1: Some Period'
                    // format, with anything after the week number being optional. It also matches
                    // with or without Markdown bold syntax (e.g. **Week 1** or Week 1).
                    const match = period.title?.match(/^(\**Week )(\d+)([\w\W]*)/);

                    if (!match) return;

                    // Change the number after 'Week' to the period's index + 1.
                    period.title = period.title.replace(match[2], index + 1);
                });
            },

            getPeriodForDate(date) {
                return _.find(this.periods, period => period.startDate < date && period.endDate >= date);
            },

            // playlist collections are stored as raw JSON on the server,
            // so it doesn't seem necessary to create an Iguana model for
            // playlist collection objects at this point.
            newPlaylistCollection(attrs = {}) {
                return _.extend(
                    {
                        title: '',
                        required_playlist_pack_ids: [],
                    },
                    attrs,
                );
            },

            addPlaylistCollection(attrs) {
                this.playlist_collections.push(this.newPlaylistCollection(attrs));
            },

            removePlaylistCollection(playlistCollection) {
                if (!playlistCollection) {
                    throw new Error('Must specify a playlistCollection');
                }

                this.playlist_collections = _.without(this.playlist_collections, playlistCollection);
            },

            getStreamPackIdsFromPeriods() {
                if (!this.supportsSchedule) {
                    return [];
                }

                return [...new Set(this.periods.map(period => period.streamLocalePackIds).flat())];
            },

            getStreamPackIdsFromPlaylistCollections(playlists) {
                if (!playlists) {
                    throw new Error('Cannot get stream pack ids without playlists');
                }
                // NOTE: `playlists` here might be instances of the `Playlist` iguana model
                // or they might just be vanilla json pulled from `frontRoyalStore`
                const playlistsByLocalePackId = keyBy(p => p.locale_pack.id)(playlists);

                const streamLocalePackIds = this.playlistPackIds
                    .map(localePackId => playlistsByLocalePackId[localePackId]?.stream_entries)
                    .filter(Boolean) // native version of _.compact
                    .flat()
                    .map(streamEntry => streamEntry.locale_pack_id);

                return [...new Set(streamLocalePackIds)]; // return array of UNIQUE stream locale pack ids
            },

            setDefaultEnrollmentDocumentsDeadline() {
                if (!this.supportsEnrollmentDocumentsDeadline) {
                    this.enrollment_documents_deadline_days_offset = null;
                    return;
                }

                // == instead of === sign to match undefined and null, but not 0
                if (this.enrollment_documents_deadline_days_offset == null) {
                    this.enrollment_documents_deadline_days_offset = -27;
                }
            },

            setDefaultOfficialTranscriptsDeadline() {
                if (!this.supportsOfficialTranscriptsDeadline) {
                    this.official_transcripts_deadline_days_offset = null;
                    return;
                }

                // == instead of === sign to match undefined and null, but not 0
                if (this.official_transcripts_deadline_days_offset == null) {
                    this.official_transcripts_deadline_days_offset = 8 * 7;
                }
            },

            supportEmailAddress(username) {
                if (username === this.program_type) {
                    // Some program types don't use the program type as the username for the default
                    // support email address. For example, the default support email address for
                    // emba_strategic_leadership isn't emba_strategic_leadership@some_domain.com;
                    // it's emba@some_domain.com.
                    throw new Error('Do not pass in the program_type as the supportEmailAddress username');
                }

                return this.constructor.supportEmailAddress(this.program_type, username);
            },

            _setDefaultEnrollmentDeadline() {
                if (!this.supportsEnrollmentDeadline) {
                    this.enrollment_deadline_days_offset = 0;
                    return;
                }

                // == instead of === sign to match undefined and null, but not 0
                if (this.enrollment_deadline_days_offset == null) {
                    this.enrollment_deadline_days_offset = 21;
                }
            },
        });
    },
]);
