window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.cohort_sections = window.locales.am.cohort_sections || {};window.locales.am.cohort_sections = {...window.locales.am.cohort_sections, ...{
    "global_short": "አለም አቀፍ",           
    "global_long": "አለም አቀፍ",           
    "s_se_asia_short": "ደቡ/ምስ እስያ",           
    "s_se_asia_long": "ደቡ/ምስ እስያ",           
    "africa_short": "አፍሪካ",           
    "africa_long": "አፍሪካ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.cohort_sections = window.locales.ar.cohort_sections || {};window.locales.ar.cohort_sections = {...window.locales.ar.cohort_sections, ...{
    "global_short": "عالمي",
    "global_long": "عالمي",
    "s_se_asia_short": "جنوب شرق آسيا",
    "s_se_asia_long": "جنوب شرق آسيا",
    "africa_short": "أفريقيا",
    "africa_long": "أفريقيا"
}
};window.locales.en = window.locales.en || {};window.locales.en.cohort_sections = window.locales.en.cohort_sections || {};window.locales.en.cohort_sections = {...window.locales.en.cohort_sections, ...{
    "global_short": "Global",
    "global_long": "Global",
    "s_se_asia_short": "S/SE Asia",
    "s_se_asia_long": "S/SE Asia",
    "africa_short": "Africa",
    "africa_long": "Africa"
}
};window.locales.es = window.locales.es || {};window.locales.es.cohort_sections = window.locales.es.cohort_sections || {};window.locales.es.cohort_sections = {...window.locales.es.cohort_sections, ...{
    "global_short": "Global",
    "global_long": "Global",
    "s_se_asia_short": "Sudeste asiático",
    "s_se_asia_long": "Sudeste asiático",
    "africa_short": "África",
    "africa_long": "África"
}
};window.locales.it = window.locales.it || {};window.locales.it.cohort_sections = window.locales.it.cohort_sections || {};window.locales.it.cohort_sections = {...window.locales.it.cohort_sections, ...{
    "global_short": "Globale",
    "global_long": "Globale",
    "s_se_asia_short": "S / SE Asia",
    "s_se_asia_long": "S / SE Asia",
    "africa_short": "Africa",
    "africa_long": "Africa"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.cohort_sections = window.locales.zh.cohort_sections || {};window.locales.zh.cohort_sections = {...window.locales.zh.cohort_sections, ...{
    "global_short": "全球",           
    "global_long": "全球",           
    "s_se_asia_short": "南亚/东南亚",           
    "s_se_asia_long": "南亚/东南亚",            
    "africa_short": "非洲",
    "africa_long": "非洲"
}
};