window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.content_item = window.locales.am.content_item || {};window.locales.am.content_item.is_content_item_mixin = window.locales.am.content_item.is_content_item_mixin || {};window.locales.am.content_item.is_content_item_mixin = {...window.locales.am.content_item.is_content_item_mixin, ...{
    "locked": "ተቆልፏል",           
    "resume": "ከቆመበት ቀጥል",           
    "start": "ጀምር",           
    "closed": "ዝግ",           
    "unknown": "ያልታወቀ",           
    "review": "ይገምግሙ"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.content_item = window.locales.ar.content_item || {};window.locales.ar.content_item.is_content_item_mixin = window.locales.ar.content_item.is_content_item_mixin || {};window.locales.ar.content_item.is_content_item_mixin = {...window.locales.ar.content_item.is_content_item_mixin, ...{
    "locked": "مغلقة",           
    "resume": "استئناف",           
    "start": "ابدأ",           
    "unknown": "غير معروف",           
    "closed": "مغلق",
    "review": "مراجعة"
}
};window.locales.en = window.locales.en || {};window.locales.en.content_item = window.locales.en.content_item || {};window.locales.en.content_item.is_content_item_mixin = window.locales.en.content_item.is_content_item_mixin || {};window.locales.en.content_item.is_content_item_mixin = {...window.locales.en.content_item.is_content_item_mixin, ...{
    "locked": "LOCKED",
    "resume": "RESUME",
    "start": "START",
    "closed": "CLOSED",
    "unknown": "Unknown",
    "review": "REVIEW"
}
};window.locales.es = window.locales.es || {};window.locales.es.content_item = window.locales.es.content_item || {};window.locales.es.content_item.is_content_item_mixin = window.locales.es.content_item.is_content_item_mixin || {};window.locales.es.content_item.is_content_item_mixin = {...window.locales.es.content_item.is_content_item_mixin, ...{
    "resume": "REANUDAR",           
    "start": "EMPEZAR",           
    "unknown": "Desconocido",           
    "locked": "BLOQUEADO",           
    "closed": "CERRADO",
    "review": "REVISAR"
}
};window.locales.it = window.locales.it || {};window.locales.it.content_item = window.locales.it.content_item || {};window.locales.it.content_item.is_content_item_mixin = window.locales.it.content_item.is_content_item_mixin || {};window.locales.it.content_item.is_content_item_mixin = {...window.locales.it.content_item.is_content_item_mixin, ...{
    "locked": "BLOCCATO",           
    "resume": "RIPRENDI",           
    "start": "INIZIA",           
    "closed": "CHIUSO",           
    "unknown": "Sconosciuto",           
    "review": "REVISIONE"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.content_item = window.locales.zh.content_item || {};window.locales.zh.content_item.is_content_item_mixin = window.locales.zh.content_item.is_content_item_mixin || {};window.locales.zh.content_item.is_content_item_mixin = {...window.locales.zh.content_item.is_content_item_mixin, ...{
    "resume": "继续",           
    "start": "开始",           
    "unknown": "未知",           
    "locked": "已锁定",           
    "closed": "已关闭",           
    "review": "审查"
}
};