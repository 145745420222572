window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.client_config = window.locales.am.client_config || {};window.locales.am.client_config.client_config = window.locales.am.client_config.client_config || {};window.locales.am.client_config.client_config = {...window.locales.am.client_config.client_config, ...{
    "native_upgrade_button_text": "አዘምን",           
    "native_upgrade_instructions": "የቅርብ ጊዜውን ስሪት ለማግኘት ከታች ያለውን ቁልፍ ይጫኑ።",           
    "web_upgrade_button_text": "አድስ",           
    "web_upgrade_instructions": "አዲሱን ስሪት ለማግኘት፣ እባክዎ አሳሽዎን ያድሱ።"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.client_config = window.locales.ar.client_config || {};window.locales.ar.client_config.client_config = window.locales.ar.client_config.client_config || {};window.locales.ar.client_config.client_config = {...window.locales.ar.client_config.client_config, ...{
    "native_upgrade_button_text": "تحديث",           
    "native_upgrade_instructions": "انقر على الزر أدناه للحصول على أحدث إصدار.",           
    "web_upgrade_button_text": "تحديث",           
    "web_upgrade_instructions": "للحصول على الإصدار الجديد، يرجى تحديث متصفحك."           
}
};window.locales.en = window.locales.en || {};window.locales.en.client_config = window.locales.en.client_config || {};window.locales.en.client_config.client_config = window.locales.en.client_config.client_config || {};window.locales.en.client_config.client_config = {...window.locales.en.client_config.client_config, ...{
    "native_upgrade_button_text": "Update",
    "native_upgrade_instructions": "Click the button below to get the latest version.",
    "web_upgrade_button_text": "Refresh",
    "web_upgrade_instructions": "To get the new version, please refresh your browser."
}
};window.locales.es = window.locales.es || {};window.locales.es.client_config = window.locales.es.client_config || {};window.locales.es.client_config.client_config = window.locales.es.client_config.client_config || {};window.locales.es.client_config.client_config = {...window.locales.es.client_config.client_config, ...{
    "native_upgrade_button_text": "Actualizar",           
    "native_upgrade_instructions": "Haz clic en el botón de abajo para obtener la última versión.",           
    "web_upgrade_button_text": "Actualizar",           
    "web_upgrade_instructions": "Para obtener la nueva versión, por favor, actualiza su navegador."           
}
};window.locales.it = window.locales.it || {};window.locales.it.client_config = window.locales.it.client_config || {};window.locales.it.client_config.client_config = window.locales.it.client_config.client_config || {};window.locales.it.client_config.client_config = {...window.locales.it.client_config.client_config, ...{
    "native_upgrade_button_text": "Aggiorna",           
    "native_upgrade_instructions": "Fai clic sul pulsante in basso per ottenere l'ultima versione.",           
    "web_upgrade_button_text": "Aggiorna",           
    "web_upgrade_instructions": "Per ottenere la nuova versione, aggiorna il browser."           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.client_config = window.locales.zh.client_config || {};window.locales.zh.client_config.client_config = window.locales.zh.client_config.client_config || {};window.locales.zh.client_config.client_config = {...window.locales.zh.client_config.client_config, ...{
    "native_upgrade_button_text": "更新",           
    "native_upgrade_instructions": "单击下面的按钮获取最新版本。",           
    "web_upgrade_button_text": "刷新",           
    "web_upgrade_instructions": "要获取新版本，请刷新您的浏览器。"           
}
};