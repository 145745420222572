window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.countries = window.locales.am.countries || {};window.locales.am.countries.country = window.locales.am.countries.country || {};window.locales.am.countries.country = {...window.locales.am.countries.country, ...{
    "AF": "አፍጋኒስታን",           
    "AL": "አልባኒያ",           
    "DZ": "አልጄሪያ",           
    "AD": "አንዶራ",           
    "AO": "አንጎላ",           
    "AG": "አንቲጓ እና ባርቡዳ",           
    "AR": "አርጀንቲና",           
    "AM": "አርሜኒያ",           
    "AU": "አውስትራሊያ",           
    "AT": "ኦስትራ",           
    "AZ": "አዘርባጃን",           
    "BS": "ባሃማስ፣ የ",           
    "BH": "ባሃሬን",           
    "BD": "ባንግላዴሽ",           
    "BB": "ባርባዶስ",           
    "BY": "ቤላሩስ",           
    "BE": "ቤልጂየም",           
    "BZ": "ቢሊዝ",           
    "BJ": "ቤኒን",           
    "BT": "ብሁታን",           
    "BO": "ቦሊቪያ",           
    "BA": "ቦስኒያ እና ሄርዞጎቪና",           
    "BW": "ቦትስዋና",           
    "BR": "ብራዚል",           
    "BN": "ብሩኔይ",           
    "BG": "ቡልጋሪያ",           
    "BF": "ቡርኪናፋሶ",           
    "BI": "ቡሩንዲ",           
    "KH": "ካምቦዲያ",           
    "CM": "ካሜሩን",           
    "CA": "ካናዳ",           
    "CV": "ኬፕ ቬሪዴ",           
    "CF": "ሴንትራል አፍሪካ ሪፑቢሊክ",           
    "TD": "ቻድ",           
    "CL": "ቺሊ",           
    "CN": "ቻይና፣ የህዝብ ሪፐብሊክ",           
    "CO": "ኮሎምቢያ",           
    "KM": "ኮሞሮስ",           
    "CD": "ኮንጎ፣ (ኮንጎ ኪንሻሳ)",           
    "CG": "ኮንጎ፣ (ኮንጎ ብራዛቪል)",           
    "CR": "ኮስታሪካ",           
    "CI": "ኮትዲቮር (አይቮሪ ኮስት)",           
    "HR": "ክሮሽያ",           
    "CU": "ኩባ",           
    "CY": "ሲፕረስ",           
    "CZ": "ቼክ ሪፐብሊክ",           
    "DK": "ዴንማሪክ",           
    "DJ": "ጅቡቲ",           
    "DM": "ዶሚኒካ",           
    "DO": "ዶሚኒካን ሪፐብሊክ",           
    "EC": "ኢኳዶር",           
    "EG": "ግብፅ",           
    "SV": "ኤልሳልቫዶር",           
    "GQ": "ኢኳቶሪያል ጊኒ",           
    "ER": "ኤርትራ",           
    "EE": "ኢስቶኒያ",           
    "ET": "ኢትዮጵያ",           
    "FJ": "ፊጂ",           
    "FI": "ፊኒላንድ",           
    "FR": "ፈረንሳይ",           
    "GA": "ጋቦን",           
    "GM": "ጋምቢያ፣ የ",           
    "GE": "ጆርጂያ",           
    "DE": "ጀርመን",           
    "GH": "ጋና",           
    "GR": "ግሪክ",           
    "GD": "ግሬናዳ",           
    "GT": "ጓቲማላ",           
    "GN": "ጊኒ",           
    "GW": "ጊኒ-ቢሳው",           
    "GY": "ጉያና",           
    "HT": "ሃይቲ",           
    "HN": "ሆንዱራስ",           
    "HU": "ሃንጋሪ",           
    "IS": "አይስላንድ",           
    "IN": "ህንድ",           
    "ID": "ኢንዶኔዥያ",           
    "IR": "ኢራን",           
    "IQ": "ኢራቅ",           
    "IE": "አይርላድ",           
    "IL": "እስራኤል",           
    "IT": "ጣሊያን",           
    "JM": "ጃማይካ",           
    "JP": "ጃፓን",           
    "JO": "ዮርዳኖስ",           
    "KZ": "ካዛኪስታን",           
    "KE": "ኬንያ",           
    "KI": "ኪሪባቲ",           
    "KP": "ኮሪያ፣ ሰሜን",           
    "KR": "ኮሪያ፣ ደቡብ",           
    "KW": "ኩዌት",           
    "KG": "ካይርጋዝስታን",           
    "LA": "ላኦስ",           
    "LV": "ላቲቪያ",           
    "LB": "ሊባኖስ",           
    "LS": "ሌሶቶ",           
    "LR": "ላይቤሪያ",           
    "LY": "ሊቢያ",           
    "LI": "ሌችቴንስቴይን",           
    "LT": "ሊቱአኒያ",           
    "LU": "ሉዘምቦርግ",           
    "MK": "መቄዶኒያ",           
    "MG": "ማዳጋስካር",           
    "MW": "ማላዊ",           
    "MY": "ማሌዥያ",           
    "MV": "ማልዲቬስ",           
    "ML": "ማሊ",           
    "MT": "ማልታ",           
    "MH": "ማርሻል አይላንድስ",           
    "MR": "ሞሪታኒያ",           
    "MU": "ሞሪሼስ",           
    "MX": "ሜክስኮ",           
    "FM": "ሚክሮኔዥያ",           
    "MD": "ሞልዶቫ",           
    "MC": "ሞናኮ",           
    "MN": "ሞንጎሊያ",           
    "ME": "ሞንቴኔግሮ",           
    "MA": "ሞሮኮ",           
    "MZ": "ሞዛምቢክ",           
    "MM": "ምያንማር (ቡርማ)",           
    "NA": "ናምቢያ",           
    "NR": "ናኡሩ",           
    "NP": "ኔፓል",           
    "NL": "ኔዜሪላንድ",           
    "NZ": "ኒውዚላንድ",           
    "NI": "ኒካራጉአ",           
    "NE": "ኒጀር",           
    "NG": "ናይጄሪያ",           
    "NO": "ኖርዌይ",           
    "OM": "ኦማን",           
    "PK": "ፓኪስታን",           
    "PW": "ፓላኡ",           
    "PA": "ፓናማ",           
    "PG": "ፓፓዋ ኒው ጊኒ",           
    "PY": "ፓራጓይ",           
    "PE": "ፔሩ",           
    "PH": "ፊሊፕን",           
    "PL": "ፖላንድ",           
    "PT": "ፖርቹጋል",           
    "QA": "ኳታር",           
    "RO": "ሮማኒያ",           
    "RU": "ራሽያ",           
    "RW": "ሩዋንዳ",           
    "KN": "ሴንት ኪትስ እና ኔቪስ",           
    "LC": "ሴንት ሉካስ",           
    "VC": "ሴንት ቪንሴንት እና ጀሬናዲኔስ",           
    "WS": "ሳሞአ",           
    "SM": "ሳን ማሪኖ",           
    "ST": "ሳኦ ቶሜ እና ፕሪንቺፔ",           
    "SA": "ሳውዲ አረብያ",           
    "SN": "ሴኔጋል",           
    "RS": "ሴርቢያ",           
    "SC": "ሲሼልስ",           
    "SL": "ሴራሊዮን",           
    "SG": "ሲንጋፖር",           
    "SK": "ስሎቫኒካ",           
    "SI": "ስሎቫኒያ",           
    "SB": "የሰሎሞን አይላንዶች",           
    "SO": "ሶማሊያ",           
    "ZA": "ደቡብ አፍሪካ",           
    "SS": "ደቡብ ሱዳን",           
    "ES": "ስፔን",           
    "LK": "ስሪ ላንካ",           
    "SD": "ሱዳን",           
    "SR": "ሱሪናሜ",           
    "SZ": "ስዋዚላድ",           
    "SE": "ስዊዲን",           
    "CH": "ስዊዘርላንድ",           
    "SY": "ሶሪያ",           
    "TJ": "ታጂኪስታን",           
    "TZ": "ታንዛኒያ",           
    "TH": "ታይላንድ",           
    "TL": "ቲሞር-ሌስቴ (ምስራቅ ቲሞር)",           
    "TG": "ቶጎ",           
    "TO": "ቶንጋ",           
    "TT": "ትሪኒዳድ እና ቶባጎ",           
    "TN": "ቱኒዝያ",           
    "TR": "ቱሪክ",           
    "TM": "ቱርክሜኒስታን",           
    "TV": "ቱቫሉ",           
    "UG": "ኡጋንዳ",           
    "UA": "ዩክሬን",           
    "AE": "ዩናይትድ አራብ ኢሚሬትስ",           
    "GB": "ዩናይትድ ኪንግደም",           
    "US": "ዩናይትድ ስቴትስ (አሜሪካ)",           
    "UY": "ኡራጋይ",           
    "UZ": "ኡዝቤክስታን",           
    "VU": "ቫኑአቱ",           
    "VA": "ቫቲካን ከተማ",           
    "VE": "ቬንዙዋላ",           
    "VN": "ቬትናም",           
    "YE": "የመን",           
    "ZM": "ዛምቢያ",           
    "ZW": "ዚምባቡዌ",           
    "TW": "ታይዋን",           
    "CX": "ክሪማስ ደሴት",           
    "CC": "ኮኮስ (ኬሊንግ) ደሴቶች",           
    "NF": "ኖርፎልክ ደሴት",           
    "NC": "ኒው ካሌዶኒያ",           
    "PF": "የፈረንሳይ ፖሊኔሺያ",           
    "YT": "ማዮት",           
    "PM": "ሴንት ፒዬር እና ሚኬሎን",           
    "WF": "ዋሊስ እና ፉቱና",           
    "CK": "ኩክ ደሴቶች",           
    "NU": "ኒይኡ",           
    "TK": "ቶኬላኡ",           
    "GG": "ገርንሴይ",           
    "IM": "አይሲሊ ኦፍ ማን",           
    "JE": "ጀርሲ",           
    "AI": "አንጉላ",           
    "BM": "ቤርሙዳ",           
    "IO": "የብሪቲሽ ህንድ ውቅያኖስ ግዛት",           
    "VG": "የብሪቲሽ ቨርጂን ደሴቶች",           
    "KY": "ኬይማን ደሴቶች",           
    "FK": "የፎክላንድ ደሴቶች (ኢስላስ ማልቪናስ)",           
    "GI": "ጊብራልታር",           
    "MS": "ሞንትሴራት",           
    "SH": "ሴንት ሄሌና",           
    "TC": "የቱርክስ እና የካይኮስ ደሴቶች",           
    "MP": "የሰሜናዊ ማሪያና ደሴቶች",           
    "PR": "ፑኤርቶ ሪኮ",           
    "AS": "የአሜሪካ ሳሞአ",           
    "GU": "ጉአሜ",           
    "VI": "የዩኤስ ቨርጂን ደሴቶች",           
    "HK": "ሆንግ ኮንግ",           
    "MO": "ማካዎ",           
    "FO": "የፋሮ ደሴቶች",           
    "GL": "ግሪንላንድ",           
    "GF": "የፈረንሳይ ጉያና",           
    "GP": "ጓዴሎፕ፣ ሴንት በርተሌሚ፣ ሴንት ማርቲን",           
    "MQ": "ማርቲኒክ",           
    "RE": "ሪዩኒየን",           
    "AX": "አላንድ",           
    "AW": "አሩባ",           
    "SJ": "ስቫልባርድ",           
    "AC": "አሴንሽን",           
    "TA": "ትሪስታን ዳ ኩንሃ",           
    "PS": "ፍልስጥኤም",           
    "BQ": "የካሪቢያን ኔዘርላንድስ",
    "XK": "ኮሶቮ"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.countries = window.locales.ar.countries || {};window.locales.ar.countries.country = window.locales.ar.countries.country || {};window.locales.ar.countries.country = {...window.locales.ar.countries.country, ...{
    "AF": "أفغانستان",
    "AL": "ألبانيا",
    "DZ": "الجزائر",
    "AD": "أندورا",
    "AO": "أنغولا",
    "AG": "أنتيغوا وبربودا",
    "AR": "الأرجنتين",
    "AM": "أرمينيا",
    "AU": "أستراليا",
    "AT": "النمسا",
    "AZ": "أذربيجان",
    "BS": "جزر البهاما",
    "BH": "البحرين",
    "BD": "بنغلاديش",
    "BB": "بربادوس",
    "BY": "بيلاروسيا",
    "BE": "بلجيكا",
    "BZ": "بليز",
    "BJ": "بنين",
    "BT": "بوتان",
    "BO": "بوليفيا",
    "BA": "البوسنة والهرسك",
    "BW": "بوتسوانا",
    "BR": "البرازيل",
    "BN": "بروناي",
    "BG": "بلغاريا",
    "BF": "بوركينا فاسو",
    "BI": "بوروندي",
    "KH": "كمبوديا",
    "CM": "الكاميرون",
    "CA": "كندا",
    "CV": "الرأس الأخضر",
    "CF": "جمهورية افريقيا الوسطى",
    "TD": "تشاد",
    "CL": "الفلفل الحار",
    "CN": "جمهورية الصين الشعبية",
    "CO": "كولومبيا",
    "KM": "جزر القمر",
    "CD": "الكونغو (الكونغو كينشاسا)",
    "CG": "الكونغو (الكونغو برازافيل)",
    "CR": "كوستا ريكا",
    "CI": "ساحل العاج (ساحل العاج)",
    "HR": "كرواتيا",
    "CU": "كوبا",
    "CY": "قبرص",
    "CZ": "الجمهورية التشيكية",
    "DK": "الدنمارك",
    "DJ": "جيبوتي",
    "DM": "دومينيكا",
    "DO": "جمهورية الدومينيكان",
    "EC": "الاكوادور",
    "EG": "مصر",
    "SV": "المنقذ",
    "GQ": "غينيا الإستوائية",
    "ER": "إريتريا",
    "EE": "إستونيا",
    "ET": "أثيوبيا",
    "FJ": "فيجي",
    "FI": "فنلندا",
    "FR": "فرنسا",
    "GA": "الجابون",
    "GM": "غامبيا",
    "GE": "جورجيا",
    "DE": "ألمانيا",
    "GH": "غانا",
    "GR": "اليونان",
    "GD": "غرينادا",
    "GT": "غواتيمالا",
    "GN": "غينيا",
    "GW": "غينيا بيساو",
    "GY": "غيانا",
    "HT": "هايتي",
    "HN": "هندوراس",
    "HU": "هنغاريا",
    "IS": "أيسلندا",
    "IN": "الهند",
    "ID": "إندونيسيا",
    "IR": "إيران",
    "IQ": "العراق",
    "IE": "أيرلندا",
    "IL": "إسرائيل",
    "IT": "إيطاليا",
    "JM": "جامايكا",
    "JP": "اليابان",
    "JO": "الأردن",
    "KZ": "كازاخستان",
    "KE": "كينيا",
    "KI": "كيريباتي",
    "KP": "كوريا الشمالية",
    "KR": "كوريا، جنوب",
    "KW": "الكويت",
    "KG": "قيرغيزستان",
    "LA": "لاوس",
    "LV": "لاتفيا",
    "LB": "لبنان",
    "LS": "ليسوتو",
    "LR": "ليبيريا",
    "LY": "ليبيا",
    "LI": "ليختنشتاين",
    "LT": "ليتوانيا",
    "LU": "لوكسمبورغ",
    "MK": "مقدونيا",
    "MG": "مدغشقر",
    "MW": "ملاوي",
    "MY": "ماليزيا",
    "MV": "جزر المالديف",
    "ML": "مالي",
    "MT": "مالطا",
    "MH": "جزر مارشال",
    "MR": "موريتانيا",
    "MU": "موريشيوس",
    "MX": "المكسيك",
    "FM": "ميكرونيزيا",
    "MD": "مولدافيا",
    "MC": "موناكو",
    "MN": "منغوليا",
    "ME": "الجبل الأسود",
    "MA": "المغرب",
    "MZ": "موزمبيق",
    "MM": "ميانمار (بورما)",
    "NA": "ناميبيا",
    "NR": "ناورو",
    "NP": "نيبال",
    "NL": "هولندا",
    "NZ": "نيوزيلاندا",
    "NI": "نيكاراغوا",
    "NE": "النيجر",
    "NG": "نيجيريا",
    "NO": "النرويج",
    "OM": "سلطنة عمان",
    "PK": "باكستان",
    "PW": "بالاو",
    "PA": "بنما",
    "PG": "بابوا غينيا الجديدة",
    "PY": "باراغواي",
    "PE": "بيرو",
    "PH": "فيلبيني",
    "PL": "بولندا",
    "PT": "البرتغال",
    "QA": "دولة قطر",
    "RO": "رومانيا",
    "RU": "روسيا",
    "RW": "رواندا",
    "KN": "سانت كيتس ونيفيس",
    "LC": "القديسة لوسيا",
    "VC": "سانت فنسنت وجزر غرينادين",
    "WS": "ساموا",
    "SM": "سان مارينو",
    "ST": "ساو تومي وبرينسيبي",
    "SA": "المملكة العربية السعودية",
    "SN": "السنغال",
    "RS": "صربيا",
    "SC": "سيشيل",
    "SL": "سيرا ليون",
    "SG": "سنغافورة",
    "SK": "سلوفاكيا",
    "SI": "سلوفينيا",
    "SB": "جزر سليمان",
    "SO": "الصومال",
    "ZA": "جنوب أفريقيا",
    "SS": "جنوب السودان",
    "ES": "إسبانيا",
    "LK": "سيريلانكا",
    "SD": "السودان",
    "SR": "سورينام",
    "SZ": "سوازيلاند",
    "SE": "السويد",
    "CH": "سويسرا",
    "SY": "سوريا",
    "TJ": "طاجيكستان",
    "TZ": "تنزانيا",
    "TH": "تايلاند",
    "TL": "تيمور ليشتي (تيمور الشرقية)",
    "TG": "توجو",
    "TO": "تونغا",
    "TT": "ترينداد وتوباغو",
    "TN": "تونس",
    "TR": "ديك رومى",
    "TM": "تركمانستان",
    "TV": "توفالو",
    "UG": "أوغندا",
    "UA": "أوكرانيا",
    "AE": "الإمارات العربية المتحدة",
    "GB": "المملكة المتحدة",
    "US": "الولايات المتحدة (الولايات المتحدة)",
    "UY": "أوروغواي",
    "UZ": "أوزبكستان",
    "VU": "فانواتو",
    "VA": "مدينة الفاتيكان",
    "VE": "فنزويلا",
    "VN": "فيتنام",
    "YE": "اليمن",
    "ZM": "زامبيا",
    "ZW": "زيمبابوي",
    "TW": "تايوان",
    "CX": "جزيرة الكريسماس",
    "CC": "جزر كوكوس (كيلينغ)",
    "NF": "جزيرة نورفولك",
    "NC": "كاليدونيا الجديدة",
    "PF": "بولينيزيا الفرنسية",
    "YT": "مايوت",
    "PM": "سانت بيير وميكلون",
    "WF": "واليس وفوتونا",
    "CK": "جزر كوك",
    "NU": "نيوي",
    "TK": "توكيلاو",
    "GG": "غيرنسي",
    "IM": "جزيرة آيل أوف مان",
    "JE": "جيرسي",
    "AI": "أنغيلا",
    "BM": "برمودا",
    "IO": "إقليم المحيط البريطاني الهندي",
    "VG": "جزر فيرجن البريطانية",
    "KY": "جزر كايمان",
    "FK": "جزر فوكلاند (جزر مالفيناس)",
    "GI": "جبل طارق",
    "MS": "مونتسيرات",
    "SH": "سانت هيلانة",
    "TC": "جزر تركس وكايكوس",
    "MP": "جزر مريانا الشمالية",
    "PR": "بورتوريكو",
    "AS": "ساموا الأمريكية",
    "GU": "غوام",
    "VI": "جزر فيرجن الأمريكية",
    "HK": "هونج كونج",
    "MO": "ماكاو",
    "FO": "جزر فاروس",
    "GL": "الأرض الخضراء",
    "GF": "غيانا الفرنسية",
    "GP": "جوادلوب ، سانت بارتيليمي ، سانت مارتن",
    "MQ": "مارتينيك",
    "RE": "جمع شمل",
    "AX": "أرض",
    "AW": "أروبا",
    "SJ": "سفالبارد",
    "AC": "الصعود",
    "TA": "تريستان دا كونها",
    "PS": "فلسطين",
    "BQ": "هولندا الكاريبية",
    "XK": "كوسوفو"
}
};window.locales.en = window.locales.en || {};window.locales.en.countries = window.locales.en.countries || {};window.locales.en.countries.country = window.locales.en.countries.country || {};window.locales.en.countries.country = {...window.locales.en.countries.country, ...{
    "AF": "Afghanistan",
    "AL": "Albania",
    "DZ": "Algeria",
    "AD": "Andorra",
    "AO": "Angola",
    "AG": "Antigua and Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaijan",
    "BS": "Bahamas, The",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarus",
    "BE": "Belgium",
    "BZ": "Belize",
    "BJ": "Benin",
    "BT": "Bhutan",
    "BO": "Bolivia",
    "BA": "Bosnia and Herzegovina",
    "BW": "Botswana",
    "BR": "Brazil",
    "BN": "Brunei",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KH": "Cambodia",
    "CM": "Cameroon",
    "CA": "Canada",
    "CV": "Cape Verde",
    "CF": "Central African Republic",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China, People's Republic of",
    "CO": "Colombia",
    "KM": "Comoros",
    "CD": "Congo, (Congo Kinshasa)",
    "CG": "Congo, (Congo Brazzaville)",
    "CR": "Costa Rica",
    "CI": "Cote d'Ivoire (Ivory Coast)",
    "HR": "Croatia",
    "CU": "Cuba",
    "CY": "Cyprus",
    "CZ": "Czech Republic",
    "DK": "Denmark",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominican Republic",
    "EC": "Ecuador",
    "EG": "Egypt",
    "SV": "El Salvador",
    "GQ": "Equatorial Guinea",
    "ER": "Eritrea",
    "EE": "Estonia",
    "ET": "Ethiopia",
    "FJ": "Fiji",
    "FI": "Finland",
    "FR": "France",
    "GA": "Gabon",
    "GM": "Gambia, The",
    "GE": "Georgia",
    "DE": "Germany",
    "GH": "Ghana",
    "GR": "Greece",
    "GD": "Grenada",
    "GT": "Guatemala",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HN": "Honduras",
    "HU": "Hungary",
    "IS": "Iceland",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Iran",
    "IQ": "Iraq",
    "IE": "Ireland",
    "IL": "Israel",
    "IT": "Italy",
    "JM": "Jamaica",
    "JP": "Japan",
    "JO": "Jordan",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KI": "Kiribati",
    "KP": "Korea, North",
    "KR": "Korea, South",
    "KW": "Kuwait",
    "KG": "Kyrgyzstan",
    "LA": "Laos",
    "LV": "Latvia",
    "LB": "Lebanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libya",
    "LI": "Liechtenstein",
    "LT": "Lithuania",
    "LU": "Luxembourg",
    "MK": "Macedonia",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Marshall Islands",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "MX": "Mexico",
    "FM": "Micronesia",
    "MD": "Moldova",
    "MC": "Monaco",
    "MN": "Mongolia",
    "ME": "Montenegro",
    "MA": "Morocco",
    "MZ": "Mozambique",
    "MM": "Myanmar (Burma)",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Netherlands",
    "NZ": "New Zealand",
    "NI": "Nicaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NO": "Norway",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PA": "Panama",
    "PG": "Papua New Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Philippines",
    "PL": "Poland",
    "PT": "Portugal",
    "QA": "Qatar",
    "RO": "Romania",
    "RU": "Russia",
    "RW": "Rwanda",
    "KN": "Saint Kitts and Nevis",
    "LC": "Saint Lucia",
    "VC": "Saint Vincent and the Grenadines",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Sao Tome and Principe",
    "SA": "Saudi Arabia",
    "SN": "Senegal",
    "RS": "Serbia",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SK": "Slovakia",
    "SI": "Slovenia",
    "SB": "Solomon Islands",
    "SO": "Somalia",
    "ZA": "South Africa",
    "SS": "South Sudan",
    "ES": "Spain",
    "LK": "Sri Lanka",
    "SD": "Sudan",
    "SR": "Suriname",
    "SZ": "Swaziland",
    "SE": "Sweden",
    "CH": "Switzerland",
    "SY": "Syria",
    "TJ": "Tajikistan",
    "TZ": "Tanzania",
    "TH": "Thailand",
    "TL": "Timor-Leste (East Timor)",
    "TG": "Togo",
    "TO": "Tonga",
    "TT": "Trinidad and Tobago",
    "TN": "Tunisia",
    "TR": "Turkey",
    "TM": "Turkmenistan",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ukraine",
    "AE": "United Arab Emirates",
    "GB": "United Kingdom",
    "US": "United States (USA)",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VA": "Vatican City",
    "VE": "Venezuela",
    "VN": "Vietnam",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe",
    "TW": "Taiwan",
    "CX": "Christmas Island",
    "CC": "Cocos (Keeling) Islands",
    "NF": "Norfolk Island",
    "NC": "New Caledonia",
    "PF": "French Polynesia",
    "YT": "Mayotte",
    "PM": "Saint Pierre and Miquelon",
    "WF": "Wallis and Futuna",
    "CK": "Cook Islands",
    "NU": "Niue",
    "TK": "Tokelau",
    "GG": "Guernsey",
    "IM": "Isle of Man",
    "JE": "Jersey",
    "AI": "Anguilla",
    "BM": "Bermuda",
    "IO": "British Indian Ocean Territory",
    "VG": "British Virgin Islands",
    "KY": "Cayman Islands",
    "FK": "Falkland Islands (Islas Malvinas)",
    "GI": "Gibraltar",
    "MS": "Montserrat",
    "SH": "Saint Helena",
    "TC": "Turks and Caicos Islands",
    "MP": "Northern Mariana Islands",
    "PR": "Puerto Rico",
    "AS": "American Samoa",
    "GU": "Guam",
    "VI": "U.S. Virgin Islands",
    "HK": "Hong Kong",
    "MO": "Macau",
    "FO": "Faroe Islands",
    "GL": "Greenland",
    "GF": "French Guiana",
    "GP": "Guadeloupe, Saint Barthelemy, Saint Martin",
    "MQ": "Martinique",
    "RE": "Reunion",
    "AX": "Aland",
    "AW": "Aruba",
    "BQ": "Caribbean Netherlands",
    "SJ": "Svalbard",
    "AC": "Ascension",
    "TA": "Tristan da Cunha",
    "PS": "Palestine",
    "XK": "Kosovo"
}
};window.locales.es = window.locales.es || {};window.locales.es.countries = window.locales.es.countries || {};window.locales.es.countries.country = window.locales.es.countries.country || {};window.locales.es.countries.country = {...window.locales.es.countries.country, ...{
    "AF": "Afganistán",
    "AL": "Albania",
    "DZ": "Argelia",
    "AD": "Andorra",
    "AO": "Angola",
    "AG": "Antigua y Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaiyán",
    "BS": "Bahamas, El",
    "BH": "Bahréin",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Bielorrusia",
    "BE": "Bélgica",
    "BZ": "Belice",
    "BJ": "Benin",
    "BT": "Bután",
    "BO": "Bolivia",
    "BA": "Bosnia y Herzegovina",
    "BW": "Botswana",
    "BR": "Brasil",
    "BN": "Brunei",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KH": "Camboya",
    "CM": "Camerún",
    "CA": "Canadá",
    "CV": "Cabo Verde",
    "CF": "República Centroafricana",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China, República Popular de",
    "CO": "Colombia",
    "KM": "Comoras",
    "CD": "Congo, (Congo Kinshasa)",
    "CG": "Congo, (Congo Brazzaville)",
    "CR": "Costa Rica",
    "CI": "Costa de Marfil (Costa de Marfil)",
    "HR": "Croacia",
    "CU": "Cuba",
    "CY": "Chipre",
    "CZ": "República Checa",
    "DK": "Dinamarca",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "República Dominicana",
    "EC": "Ecuador",
    "EG": "Egipto",
    "SV": "El Salvador",
    "GQ": "Guinea Ecuatorial",
    "ER": "Eritrea",
    "EE": "Estonia",
    "ET": "Etiopía",
    "FJ": "Fiyi",
    "FI": "Finlandia",
    "FR": "Francia",
    "GA": "Gabón",
    "GM": "Gambia, el",
    "GE": "Georgia",
    "DE": "Alemania",
    "GH": "Ghana",
    "GR": "Grecia",
    "GD": "Granada",
    "GT": "Guatemala",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guayana",
    "HT": "Haití",
    "HN": "Honduras",
    "HU": "Hungría",
    "IS": "Islandia",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Iran",
    "IQ": "Irak",
    "IE": "Irlanda",
    "IL": "Israel",
    "IT": "Italia",
    "JM": "Jamaica",
    "JP": "Japón",
    "JO": "Jordán",
    "KZ": "Kazajstán",
    "KE": "Kenia",
    "KI": "Kiribati",
    "KP": "Corea del Norte",
    "KR": "Corea del Sur",
    "KW": "Kuwait",
    "KG": "Kirguistán",
    "LA": "Laos",
    "LV": "Letonia",
    "LB": "Líbano",
    "LS": "Lesoto",
    "LR": "Liberia",
    "LY": "Libia",
    "LI": "Liechtenstein",
    "LT": "Lituania",
    "LU": "Luxemburgo",
    "MK": "macedonia",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malasia",
    "MV": "Maldivas",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Islas Marshall",
    "MR": "Mauritania",
    "MU": "Mauricio",
    "MX": "México",
    "FM": "Micronesia",
    "MD": "Moldavia",
    "MC": "Mónaco",
    "MN": "Mongolia",
    "ME": "Montenegro",
    "MA": "Marruecos",
    "MZ": "Mozambique",
    "MM": "Myanmar (Birmania)",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Países Bajos",
    "NZ": "Nueva Zelanda",
    "NI": "Nicaragua",
    "NE": "Níger",
    "NG": "Nigeria",
    "NO": "Noruega",
    "OM": "Omán",
    "PK": "Pakistán",
    "PW": "Palau",
    "PA": "Panamá",
    "PG": "Papúa Nueva Guinea",
    "PY": "Paraguay",
    "PE": "Perú",
    "PH": "Filipinas",
    "PL": "Polonia",
    "PT": "Portugal",
    "QA": "Katar",
    "RO": "Rumania",
    "RU": "Rusia",
    "RW": "Ruanda",
    "KN": "Saint Kitts y Nevis",
    "LC": "Santa Lucía",
    "VC": "San Vicente y las Granadinas",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Santo Tomé y Príncipe",
    "SA": "Arabia Saudita",
    "SN": "Senegal",
    "RS": "Serbia",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapur",
    "SK": "Eslovaquia",
    "SI": "Eslovenia",
    "SB": "Islas Salomón",
    "SO": "Somalia",
    "ZA": "Sudáfrica",
    "SS": "Sudán del Sur",
    "ES": "España",
    "LK": "Sri Lanka",
    "SD": "Sudán",
    "SR": "Surinam",
    "SZ": "Swazilandia",
    "SE": "Suecia",
    "CH": "Suiza",
    "SY": "Siria",
    "TJ": "Tayikistán",
    "TZ": "Tanzania",
    "TH": "Tailandia",
    "TL": "Timor-Leste (Timor Oriental)",
    "TG": "Ir",
    "TO": "Tonga",
    "TT": "Trinidad y Tobago",
    "TN": "Túnez",
    "TR": "pavo",
    "TM": "Turkmenistán",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ucrania",
    "AE": "Emiratos Árabes Unidos",
    "GB": "Reino Unido",
    "US": "Estados Unidos (EE. UU.)",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VA": "Ciudad del Vaticano",
    "VE": "Venezuela",
    "VN": "Vietnam",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabue",
    "TW": "Taiwán",
    "CX": "Isla de Navidad",
    "CC": "Islas Cocos (Keeling)",
    "NF": "Isla Norfolk",
    "NC": "Nueva Caledonia",
    "PF": "Polinesia francés",
    "YT": "Mayotte",
    "PM": "San Pedro y Miquelón",
    "WF": "Wallis y Futuna",
    "CK": "Islas Cook",
    "NU": "Niue",
    "TK": "Tokelau",
    "GG": "Guernsey",
    "IM": "Isla del hombre",
    "JE": "Jersey",
    "AI": "Anguila",
    "BM": "islas Bermudas",
    "IO": "Territorio Británico del Océano Índico",
    "VG": "Islas Vírgenes Británicas",
    "KY": "Islas Caimán",
    "FK": "Islas Malvinas (Falkland Islands)",
    "GI": "Gibraltar",
    "MS": "Montserrat",
    "SH": "Santa elena",
    "TC": "Islas Turcas y Caicos",
    "MP": "Islas Marianas del Norte",
    "PR": "Puerto Rico",
    "AS": "Samoa Americana",
    "GU": "Guam",
    "VI": "Islas Vírgenes de EE.UU",
    "HK": "Hong Kong",
    "MO": "Macao",
    "FO": "Islas Faroe",
    "GL": "Groenlandia",
    "GF": "Guayana Francesa",
    "GP": "Guadalupe, San Bartolomé, San Martín",
    "MQ": "Martinica",
    "RE": "Reunión",
    "AX": "Una tierra",
    "AW": "Aruba",
    "SJ": "Svalbard",
    "AC": "Ascensión",
    "TA": "Tristan da Cunha",
    "PS": "Palestina",
    "BQ": "Caribe neerlandés",
    "XK": "Kosovo"
}
};window.locales.it = window.locales.it || {};window.locales.it.countries = window.locales.it.countries || {};window.locales.it.countries.country = window.locales.it.countries.country || {};window.locales.it.countries.country = {...window.locales.it.countries.country, ...{
    "AF": "Afghanistan",
    "AL": "Albania",
    "DZ": "Algeria",
    "AD": "Andorra",
    "AO": "Angola",
    "AG": "Antigua e Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaigian",
    "BS": "Bahamas, The",
    "BH": "Bahrein",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Bielorussia",
    "BE": "Belgio",
    "BZ": "Belize",
    "BJ": "Benin",
    "BT": "Bhutan",
    "BO": "Bolivia",
    "BA": "Bosnia Erzegovina",
    "BW": "Botswana",
    "BR": "Brasile",
    "BN": "Brunei",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KH": "Cambogia",
    "CM": "Camerun",
    "CA": "Canada",
    "CV": "Cape Verde",
    "CF": "Repubblica Centrafricana",
    "TD": "Chad",
    "CL": "chili",
    "CN": "Cina, Repubblica Popolare di",
    "CO": "Colombia",
    "KM": "Comore",
    "CD": "Congo, (Congo Kinshasa)",
    "CG": "Congo, (Congo Brazzaville)",
    "CR": "Costa Rica",
    "CI": "Costa d'Avorio (Costa d'Avorio)",
    "HR": "Croazia",
    "CU": "Cuba",
    "CY": "Cipro",
    "CZ": "Repubblica Ceca",
    "DK": "Danimarca",
    "DJ": "Gibuti",
    "DM": "dominica",
    "DO": "Repubblica Dominicana",
    "EC": "Ecuador",
    "EG": "Egitto",
    "SV": "Il salvatore",
    "GQ": "Guinea Equatoriale",
    "ER": "Eritrea",
    "EE": "Estonia",
    "ET": "Etiopia",
    "FJ": "Figi",
    "FI": "Finlandia",
    "FR": "Francia",
    "GA": "Gabon",
    "GM": "Gambia, The",
    "GE": "Georgia",
    "DE": "Germania",
    "GH": "Ghana",
    "GR": "Grecia",
    "GD": "Grenada",
    "GT": "Guatemala",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HN": "Honduras",
    "HU": "Ungheria",
    "IS": "Islanda",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Iran",
    "IQ": "Iraq",
    "IE": "Irlanda",
    "IL": "Israele",
    "IT": "Italia",
    "JM": "Giamaica",
    "JP": "Giappone",
    "JO": "Giordania",
    "KZ": "Kazakistan",
    "KE": "Kenia",
    "KI": "Kiribati",
    "KP": "Corea del Nord",
    "KR": "Corea del Sud",
    "KW": "Kuwait",
    "KG": "Kirghizistan",
    "LA": "Laos",
    "LV": "Lettonia",
    "LB": "Libano",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libia",
    "LI": "Liechtenstein",
    "LT": "Lituania",
    "LU": "Lussemburgo",
    "MK": "Macedonia",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldive",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Isole Marshall",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "MX": "Messico",
    "FM": "Micronesia",
    "MD": "Moldavia",
    "MC": "Monaco",
    "MN": "Mongolia",
    "ME": "Montenegro",
    "MA": "Marocco",
    "MZ": "Mozambico",
    "MM": "Birmania (Birmania)",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Olanda",
    "NZ": "Nuova Zelanda",
    "NI": "Nicaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NO": "Norvegia",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PA": "Panama",
    "PG": "Papua Nuova Guinea",
    "PY": "Paraguay",
    "PE": "Perù",
    "PH": "Filippine",
    "PL": "Polonia",
    "PT": "Portogallo",
    "QA": "Qatar",
    "RO": "Romania",
    "RU": "Russia",
    "RW": "Ruanda",
    "KN": "Saint Kitts e Nevis",
    "LC": "Santa Lucia",
    "VC": "Saint Vincent e Grenadine",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Sao Tomé e Principe",
    "SA": "Saudi Arabia",
    "SN": "Senegal",
    "RS": "Serbia",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SK": "Slovacchia",
    "SI": "Slovenia",
    "SB": "Isole Salomone",
    "SO": "Somalia",
    "ZA": "Sud Africa",
    "SS": "Sudan del Sud",
    "ES": "Spagna",
    "LK": "Sri Lanka",
    "SD": "Sudan",
    "SR": "Suriname",
    "SZ": "Swaziland",
    "SE": "Svezia",
    "CH": "Svizzera",
    "SY": "Siria",
    "TJ": "Tagikistan",
    "TZ": "Tanzania",
    "TH": "Tailandia",
    "TL": "Timor Est (Timor Est)",
    "TG": "Andare",
    "TO": "Tonga",
    "TT": "Trinidad e Tobago",
    "TN": "Tunisia",
    "TR": "tacchino",
    "TM": "Turkmenistan",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ucraina",
    "AE": "Emirati Arabi Uniti",
    "GB": "Regno Unito",
    "US": "Stati Uniti (USA)",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VA": "Città del Vaticano",
    "VE": "Venezuela",
    "VN": "Vietnam",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe",
    "TW": "Taiwan",
    "CX": "Isola di Natale",
    "CC": "Isole Cocos (Keeling)",
    "NF": "Isola Norfolk",
    "NC": "Nuova Caledonia",
    "PF": "Polinesia francese",
    "YT": "Mayotte",
    "PM": "Saint Pierre e Miquelon",
    "WF": "Wallis e Futuna",
    "CK": "Isole Cook",
    "NU": "Niue",
    "TK": "Tokelau",
    "GG": "Guernsey",
    "IM": "Isola di Man",
    "JE": "maglia",
    "AI": "Anguilla",
    "BM": "Bermuda",
    "IO": "Territorio britannico dell'Oceano Indiano",
    "VG": "Isole Vergini Britanniche",
    "KY": "Isole Cayman",
    "FK": "Isole Falkland (Isole Malvinas)",
    "GI": "Gibilterra",
    "MS": "Montserrat",
    "SH": "Sant'Elena",
    "TC": "Isole Turks e Caicos",
    "MP": "Isole Marianne settentrionali",
    "PR": "Porto Rico",
    "AS": "Samoa americane",
    "GU": "Guami",
    "VI": "Isole Vergini americane",
    "HK": "Hong Kong",
    "MO": "Macau",
    "FO": "Isole Faroe",
    "GL": "Groenlandia",
    "GF": "Guiana francese",
    "GP": "Guadalupa, Saint Barthelemy, Saint Martin",
    "MQ": "Martinica",
    "RE": "Riunione",
    "AX": "Una terra",
    "AW": "Aruba",
    "SJ": "Svalbard",
    "AC": "Ascensione",
    "TA": "Tristan da Cunha",
    "PS": "Palestina",
    "BQ": "Paesi Bassi caraibici",
    "XK": "Kosovo"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.countries = window.locales.zh.countries || {};window.locales.zh.countries.country = window.locales.zh.countries.country || {};window.locales.zh.countries.country = {...window.locales.zh.countries.country, ...{
    "AF": "阿富汗",
    "AL": "阿尔巴尼亚",
    "DZ": "阿尔及利亚",
    "AD": "安道尔",
    "AO": "安哥拉",
    "AG": "安提瓜和巴布达",
    "AR": "阿根廷",
    "AM": "亚美尼亚",
    "AU": "澳大利亚",
    "AT": "奥地利",
    "AZ": "阿塞拜疆",
    "BS": "巴哈马",
    "BH": "巴林",
    "BD": "孟加拉国",
    "BB": "巴巴多斯",
    "BY": "白俄罗斯",
    "BE": "比利时",
    "BZ": "伯利兹",
    "BJ": "贝宁",
    "BT": "不丹",
    "BO": "玻利维亚",
    "BA": "波斯尼亚和黑塞哥维那（简称：波黑）",
    "BW": "博茨瓦纳",
    "BR": "巴西",
    "BN": "文莱",
    "BG": "保加利亚",
    "BF": "布基纳法索",
    "BI": "布隆迪",
    "KH": "柬埔寨",
    "CM": "喀麦隆",
    "CA": "加拿大",
    "CV": "佛得角",
    "CF": "中非共和国",
    "TD": "乍得",
    "CL": "智利",            
    "CN": "中国，中华人民共和国",
    "CO": "哥伦比亚",
    "KM": "科摩罗",
    "CD": "刚果，（刚果金沙萨）",
    "CG": "刚果，（刚果布拉柴维尔）",
    "CR": "哥斯达黎加",
    "CI": "象牙海岸",            
    "HR": "克罗地亚",
    "CU": "古巴",
    "CY": "塞浦路斯",
    "CZ": "捷克共和国",
    "DK": "丹麦",
    "DJ": "吉布提",
    "DM": "多米尼加",
    "DO": "多明尼加共和国",
    "EC": "厄瓜多尔",
    "EG": "埃及",
    "SV": "萨尔瓦多",            
    "GQ": "赤道几内亚",
    "ER": "厄立特里亚",
    "EE": "爱沙尼亚",
    "ET": "埃塞俄比亚",
    "FJ": "斐济",
    "FI": "芬兰",
    "FR": "法国",
    "GA": "加蓬",
    "GM": "冈比亚",
    "GE": "乔治亚州",
    "DE": "德国",
    "GH": "加纳",
    "GR": "希腊",
    "GD": "格林纳达",
    "GT": "危地马拉",
    "GN": "几内亚",
    "GW": "几内亚比绍",
    "GY": "圭亚那",
    "HT": "海地",
    "HN": "洪都拉斯",
    "HU": "匈牙利",
    "IS": "冰岛",
    "IN": "印度",
    "ID": "印度尼西亚",
    "IR": "伊朗",
    "IQ": "伊拉克",
    "IE": "爱尔兰",
    "IL": "以色列",
    "IT": "意大利",
    "JM": "牙买加",
    "JP": "日本",
    "JO": "约旦",
    "KZ": "哈萨克斯坦",
    "KE": "肯尼亚",
    "KI": "基里巴斯",
    "KP": "韩国、北",
    "KR": "韩国、南",
    "KW": "科威特",
    "KG": "吉尔吉斯斯坦",
    "LA": "老挝",
    "LV": "拉脱维亚",
    "LB": "黎巴嫩",
    "LS": "莱索托",
    "LR": "利比里亚",
    "LY": "利比亚",
    "LI": "列支敦士登",
    "LT": "立陶宛",
    "LU": "卢森堡",
    "MK": "马其顿",
    "MG": "马达加斯加",
    "MW": "马拉维",
    "MY": "马来西亚",
    "MV": "马尔代夫",
    "ML": "马里",
    "MT": "马耳他",
    "MH": "马绍尔群岛",
    "MR": "毛里塔尼亚",
    "MU": "毛里求斯",
    "MX": "墨西哥",
    "FM": "密克罗尼西亚",
    "MD": "摩尔达维亚",
    "MC": "摩纳哥",
    "MN": "蒙古",
    "ME": "黑山",
    "MA": "摩洛哥",
    "MZ": "莫桑比克",
    "MM": "缅甸",            
    "NA": "纳米比亚",
    "NR": "瑙鲁",
    "NP": "尼泊尔",
    "NL": "荷兰",
    "NZ": "新西兰",
    "NI": "尼加拉瓜",
    "NE": "尼日尔",
    "NG": "尼日利亚",
    "NO": "挪威",
    "OM": "阿曼",
    "PK": "巴基斯坦",
    "PW": "帕劳",
    "PA": "巴拿马",
    "PG": "巴布亚新几内亚",
    "PY": "巴拉圭",
    "PE": "秘鲁",
    "PH": "菲律宾",
    "PL": "波兰",
    "PT": "葡萄牙",
    "QA": "卡塔尔",
    "RO": "罗马尼亚",
    "RU": "俄罗斯",
    "RW": "卢旺达",
    "KN": "圣基茨和尼维斯",
    "LC": "圣卢西亚",
    "VC": "圣文森特和格林纳丁斯",
    "WS": "萨摩亚",
    "SM": "圣马力诺",
    "ST": "圣多美和普林西比",
    "SA": "沙特阿拉伯",
    "SN": "塞内加尔",
    "RS": "塞尔维亚",
    "SC": "塞舌尔",
    "SL": "塞拉利昂",
    "SG": "新加坡",
    "SK": "斯洛伐克",
    "SI": "斯洛文尼亚",
    "SB": "所罗门群岛",
    "SO": "索马里",
    "ZA": "南非",
    "SS": "南苏丹",
    "ES": "西班牙",
    "LK": "斯里兰卡",
    "SD": "苏丹",
    "SR": "苏里南",
    "SZ": "斯威士兰",
    "SE": "瑞典",
    "CH": "瑞士",
    "SY": "叙利亚",
    "TJ": "塔吉克斯坦",
    "TZ": "坦桑尼亚",
    "TH": "泰国",
    "TL": "东帝汶",            
    "TG": "多哥",
    "TO": "汤加",
    "TT": "特立尼达和多巴哥",
    "TN": "突尼斯",
    "TR": "土耳其",            
    "TM": "土库曼斯坦",
    "TV": "图瓦卢",
    "UG": "乌干达",
    "UA": "乌克兰",
    "AE": "阿拉伯联合酋长国",
    "GB": "英国",
    "US": "美国",            
    "UY": "乌拉圭",
    "UZ": "乌兹别克斯坦",
    "VU": "瓦努阿图",
    "VA": "梵蒂冈城",
    "VE": "委内瑞拉",
    "VN": "越南",
    "YE": "也门",
    "ZM": "赞比亚",
    "ZW": "津巴布韦",
    "TW": "台湾",
    "CX": "圣诞岛",
    "CC": "科科斯（基林）群岛",
    "NF": "诺福克岛",
    "NC": "新喀里多尼亚",
    "PF": "法属波利尼西亚",
    "YT": "马约特岛",
    "PM": "圣皮埃尔和密克隆",
    "WF": "瓦利斯和富图纳",
    "CK": "库克群岛",
    "NU": "纽埃",
    "TK": "托克劳",
    "GG": "根西岛",
    "IM": "马恩岛",
    "JE": "泽西",            
    "AI": "安圭拉",
    "BM": "百慕大",
    "IO": "英属印度洋领地",
    "VG": "英属维尔京群岛",
    "KY": "开曼群岛",
    "FK": "福克兰群岛（马尔维纳斯群岛）",
    "GI": "直布罗陀",
    "MS": "蒙特塞拉特",
    "SH": "圣赫勒拿",
    "TC": "特克斯和凯科斯群岛",
    "MP": "北马里亚纳群岛",
    "PR": "波多黎各",
    "AS": "美属萨摩亚",
    "GU": "关岛",
    "VI": "美属维尔京群岛",
    "HK": "香港",
    "MO": "澳门",
    "FO": "法罗群岛",
    "GL": "格陵兰",
    "GF": "法属圭亚那",
    "GP": "瓜德罗普岛、圣巴泰勒米岛、圣马丁岛",
    "MQ": "马提尼克",
    "RE": "留尼汪",            
    "AX": "奥兰",            
    "AW": "阿鲁巴岛",
    "AN": "荷属安的列斯",
    "SJ": "斯瓦尔巴群岛",
    "TA": "特里斯坦·达库尼亚",
    "PS": "巴勒斯坦",
    "BQ": "加勒比海荷兰",
    "AC": "提升",
    "XK": "科索沃"
}
};