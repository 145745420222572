window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.client_config = window.locales.am.client_config || {};window.locales.am.client_config.client_config_interceptor = window.locales.am.client_config.client_config_interceptor || {};window.locales.am.client_config.client_config_interceptor = {...window.locales.am.client_config.client_config_interceptor, ...{
    "a_new_version_is_available": "የ {{brandName}} አዲስ ስሪት አለ!",           
    "update_available_cordova": "ዝማኔ አለ",           
    "update_is_required": "የ {{brandName}} አዲስ ስሪት አለ እናም ዝማኔ ያስፈልጋል።",           
    "update_is_required_cordova": "ማዘመን ያስፈልጋል"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.client_config = window.locales.ar.client_config || {};window.locales.ar.client_config.client_config_interceptor = window.locales.ar.client_config.client_config_interceptor || {};window.locales.ar.client_config.client_config_interceptor = {...window.locales.ar.client_config.client_config_interceptor, ...{
    "update_available_cordova": "التحديث متاح",           
    "update_is_required_cordova": "التحديث مطلوب",           
    "a_new_version_is_available": "يتوفر إصدار جديد من {{brandName}} !",
    "update_is_required": "يتوفر إصدار جديد من {{brandName}} ويتطلب التحديث."
}
};window.locales.en = window.locales.en || {};window.locales.en.client_config = window.locales.en.client_config || {};window.locales.en.client_config.client_config_interceptor = window.locales.en.client_config.client_config_interceptor || {};window.locales.en.client_config.client_config_interceptor = {...window.locales.en.client_config.client_config_interceptor, ...{
    "a_new_version_is_available": "A new version of {{brandName}} is available!",
    "update_available_cordova": "Update Available",
    "update_is_required": "A new version of {{brandName}} is available and an update is required.",
    "update_is_required_cordova": "Update Required"
}
};window.locales.es = window.locales.es || {};window.locales.es.client_config = window.locales.es.client_config || {};window.locales.es.client_config.client_config_interceptor = window.locales.es.client_config.client_config_interceptor || {};window.locales.es.client_config.client_config_interceptor = {...window.locales.es.client_config.client_config_interceptor, ...{
    "update_available_cordova": "Actualización disponible",           
    "update_is_required_cordova": "Actualización necesaria",           
    "a_new_version_is_available": "¡Una nueva versión de {{brandName}} está disponible!",
    "update_is_required": "Una nueva versión de {{brandName}} está disponible y se requiere una actualización."
}
};window.locales.it = window.locales.it || {};window.locales.it.client_config = window.locales.it.client_config || {};window.locales.it.client_config.client_config_interceptor = window.locales.it.client_config.client_config_interceptor || {};window.locales.it.client_config.client_config_interceptor = {...window.locales.it.client_config.client_config_interceptor, ...{
    "update_available_cordova": "Aggiornamento Disponibile",           
    "update_is_required_cordova": "Aggiornamento necessario",           
    "a_new_version_is_available": "È disponibile una nuova versione di {{brandName}} !",
    "update_is_required": "È disponibile una nuova versione di {{brandName}} ed è richiesto un aggiornamento."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.client_config = window.locales.zh.client_config || {};window.locales.zh.client_config.client_config_interceptor = window.locales.zh.client_config.client_config_interceptor || {};window.locales.zh.client_config.client_config_interceptor = {...window.locales.zh.client_config.client_config_interceptor, ...{
    "update_available_cordova": "有可用更新",           
    "update_is_required_cordova": "需要更新",           
    "a_new_version_is_available": "{{brandName}}的全新版本已推出！",           
    "update_is_required": "{{brandName}}的新版本已推出，可进行更新。"           
}
};